<template>
	<v-expansion-panels v-model="model">
		<v-expansion-panel>
			<v-expansion-panel-header>
				<template v-slot:default>
					<div>
						<div class="icon-container">
							<v-icon v-if="lock" size="24px" color="gray">mdi-lock-outline</v-icon>
							<img v-if="warning" src="@/assets/icons/icon-warning.svg" style="width:32px;height:32px;" class="mt-2"/>
							<img v-if="error" src="@/assets/icons/icon-error.svg" style="width:32px;height:32px;" class="error-icon mt-2"/>
						</div>
						<div :class="warning || error ? 'text-container' : ''">
							{{ title }}
							<span v-if="warning" class="warning-text"><br/>{{ message }}</span>
							<span v-if="error" class="error-inline"><br/>{{ message }}</span>
						</div>
					</div>
				</template>
			</v-expansion-panel-header>
			<v-expansion-panel-content class="pt-2" :class="{ disabled: lock }">
				<slot></slot>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
export default {
	props: {
		title: String,
		dataCy: String,
		lock: Boolean,
		warning: Boolean,
		error: Boolean,
		message: String,
		expanded: Boolean
	},
	data() {
		return {
			model: -1
		}
	},
	methods: {
		open() {
			this.model = 0
		},
		close() {
			this.model = -1
		},
		toggle() {
			if (this.model === -1) {
				this.open()
			} else {
				this.close()
			}	
		},
	},
	mounted() {
		if (this.expanded) {
			this.model = 0
		}
	}
}
</script>

<style scoped>
.v-expansion-panel-header { font-size: 20pt !important; padding: 20px 25px 20px 25px !important; border-bottom: 1px solid #dedede;}
.disabled { opacity: 0.75; pointer-events: none; background-color: #fafafa; }
.warning-text { font-size: 14pt !important; color: #ffb400 !important}
.error-inline { font-size: 14pt !important; color: #f34545 !important; background-color: #ffffff !important;}
.icon-container { width: 48px; float: left;}
.text-container { margin-left: 48px; }
.error-icon {filter: brightness(100%) saturate(400%);}
</style>