<template>
	<v-app id="app">
		<v-main fluid>
      <NavBar ref="navbar"
				@show-login="showLogin"
				@show-dashboard="showDashboard"
        @event="navbarEvent"
			/>

			<router-view ref="view"
				@load-application="loadApplication"
				@show-login="showLogin"
				@show-dashboard="showDashboard"
			/>

      <Footer/>
		</v-main>
	</v-app>
</template>

<script>
import Vue from 'vue'
import Vuetify from "vuetify"
Vue.use(Vuetify)
import HttpUtil from "./plugins/HttpUtil.js"
Vue.use(HttpUtil)

import 'vue-loading-overlay/dist/vue-loading.css'
import NavBar from '@/components/common/NavBar.vue'
import Footer from './components/common/Footer.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'App',
	components: { NavBar, Footer },
  mixins: [ Common ],

  created() {
		this.$root.app = this
	},

  async mounted() {
    await this.getLocales()
    await this.getSchema()
  },

	methods: {
		loadApplication() {
      this.$router.push({path: this.$store.state.selectedAppComponent.fields.link.de})
		},
		async showLogin() {
      await this.$store.commit('setLoggedInUser', null)
      this.$router.push('/')
		},
		async showDashboard() {
      await this.$store.commit('setSelectedAppComponent', null)
      await this.$store.commit('setSearchString', null)
			this.$router.push('/dashboard')
		},
    showComponent() {
      console.log("showComponent")
    },
    navbarEvent() {
      console.log("navbarEvent")
    }
	}
}
</script>

<style>
@font-face {
  font-family: 'Inter', sans-serif;
  src: url("assets/fonts/Inter-VariableFont.ttf");
}

/* General Styles */
#app {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black !important;
  font-size: 12pt;
  background-color: #f2f2f2;
}
#card {
  max-width: 840px;
  padding: 32px 48px;
}

body {
  font-family: 'Inter', sans-serif;
}
h2 {
  font-weight: normal;
}
.main {
  position: fixed;
  top: 60px;
  background-color: #f4f5f7;
  max-height: calc(100vh - 50px);
  overflow: scroll;
}
.detail-container {
  margin-top: 45px;
  margin-left: 300px !important;
  width: calc(100vw - 300px) !important;
}
.dialog-container {
  width: 100%;
  padding: 20px;
}
.navbar {
	background-color: #2d2e2f;
  position: fixed;
	top: 0;
	left: 0;
  width: 100%;
  height: 56px;
	color: #efefef;
  z-index: 999;
}
.logo-svg {
    width: 40px !important;
    height: 40px !important;
    max-width: 40px !important;
    max-height: 40px !important; 
    margin: 10px !important;
}
.alert {
  position: fixed !important;
  top: 56px !important;
  left: 0;
  width: 100%;
  z-index: 999;
  white-space: pre-line;
  line-height: 20px !important;
  text-transform: none !important;
  letter-spacing: 0 !important;
}
.toolbar {
	border: 1px solid #c0c0c0;
  background-color: #fff;
  border-radius: 4px;
  position: relative;
  width: 100%;
  padding: 25px;
}
.filter {
	min-width:350px !important;
	overflow: hidden !important;
}
.pointer {
  cursor: pointer;
}
.mandatory {
  color: red
}
.v-select__slot {
  color: #999999 !important;
  font-size: 14pt !important;
  min-height: 48px;
}
.sub-title {
  font-size: 20px;
}
.sidebar-title {
  font-size: 10pt;
  font-weight: 500;
  text-transform: uppercase;
  color: rgb(90, 101, 124);
  border-bottom: 0px none;
  margin: 0px;
  line-height: 2;
  letter-spacing: 1px;
  border-bottom: thin rgba(0,0,0,.12) solid;
}
.sidebar-block {
  padding-top: 10px;
  padding-bottom: 20px;
}

/* Table */
.tableCard { 
  border-radius: 5px; 
  overflow: hidden; 
  min-width: 100%; 
  max-height: 50%;
  }
.tableCard span { white-space: nowrap; text-overflow: ellipsis; }
.tableFooter { box-shadow: none !important; background-color:#f4f4f4 !important; }
.truncate { max-width: 1px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; }

.theme--light.v-data-table {
  border-radius: 5px;
}
.theme--light.v-data-table.v-data-table--fixed-header thead th {
  background-color: #808080 !important;
  color: #ffffff !important;
  font-size: 14pt;
  font-weight: normal;
}
.v-data-table-header th.sortable {
  color: #ffffff !important;
}
.v-data-table tr {
  cursor: pointer;
}
.v-data-table td {
  font-size: 15pt !important;
}

.error-row {
  background-color: #e74d4c;
  color: #ffffff;
}
.error-row:hover {
  background-color: #ebbfbf !important;
  color: #000000;
}
.clear-row {
  background-color: transparent;
  color: #000000;
}

/* Div Field */
div.field { position: relative; margin-bottom: 10px; padding-bottom: 10px; margin-top: 20px; padding-top: 20px; padding-left: 15px; }
div.field .v-input { margin-top: 12px; }
div.field .v-label { display: block; }
.field.left-border:before { content: ""; position: absolute; left: 0; top: 15px; bottom: 0px;  width: 100%; border-left:3px solid #ddd; }

/* Buttons */
.btn {
  background-color:  rgba(9,30,66,.08);
  border: thin #dddddd solid;
  min-height: 48px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
}
.btn-row {
  background-color:  rgba(9,30,66,.08);
  border: thin #dddddd solid;
  min-height: 56px !important;
  min-width: 56px !important;
  max-height: 56px !important;
  max-width: 56px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
}
.btn-sml {
  background-color:  rgba(9,30,66,.08);
  border: thin #dddddd solid;
  min-height: 32px !important;
  min-width: 32px !important;
  max-height: 32px !important;
  max-width: 32px !important;
  display: flex !important;
  align-items: center !important;
  border-radius: 5px !important;
  font-family: 'Inter', sans-serif;
  font-size: 12pt !important;
  font-weight: normal !important;
  letter-spacing: 0 !important;
  text-transform: none !important;
}
.green {
  background-color: #62c522 !important;
  border: thin #58b01e solid !important;
}
.red {
  background: #f34545 !important;
  border: thin #e74d4c solid !important;
}
.blue {
  background-color: #0ba7e1 !important;
  border: thin #088ec0 solid !important;
}
.orange {
  background-color: #fe8739 !important;
  border: thin #e47a33 solid !important;
}
.grey {
  background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
  border: thin #dddddd solid !important;
}
.ma-rgt { margin-right: 10px !important }
.help { font-size: 11pt; color: gray; }
</style>