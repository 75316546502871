<template>
	<div class='EntryFilter'>
		<v-row class="itemContainer pa-2">
			<div>
				<select
          class="pa-1"
					v-model="value.contentType"
					@change="$emit('input', value)"
				>
					<option v-for="type of allowedTypes" :key="type">{{ type }}</option>
				</select>
			</div>
			<div>
				<input type="text" placeholder="Type to search" class="pa-1"
					v-model="value.search"
					@change="$emit('input', value)"
				/>
			</div>
		</v-row>
	</div>
</template>

<script>
export default {
	name: 'EntryFilter',
	props: {
		locale: String,
		value: Object, 
		def: Object,
	},
	data: () => ({
		allowedTypes: Array,
	}),
	watch: {
		def(n) {
			this.allowedTypes = this.def.validations[0].linkContentType
		},
	},
	mounted() {
		this.allowedTypes = ['client', 'region', 'ticketType', 'productCategory', 'salesChannel', 'store', 'media', 'reduction', 'taxClass', 'marketplace']

		// TODO: support more cases: multi type?, multi validations
		if (this.def?.validations?.[0]?.linkContentType) {
			this.allowedTypes = this.def.validations?.[0]?.linkContentType
			this.value.contentType = this.allowedTypes[0]
			this.$emit('input', this.value)
		}
	},
}
</script>

<style scoped>
.itemContainer { border: 1px solid #ccc; border-radius: 7px; background-color: white; }
select {width: 100%; background-color: #eee; border-radius: 5px; }
input { width: 100%; }
</style>