<template>
	<v-container class="main" fluid fill-width>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div class="toolbar">
			<v-row align="center">
				<v-btn class="btn ma-rgt" small elevation="0" @click="goback()">
					<v-icon>mdi-arrow-left-circle</v-icon>
				</v-btn>

				<!-- Search -->
				<v-text-field outlined dense clearable hide-details height="48" class="ma-rgt"
					prepend-inner-icon="mdi-magnify"
					placeholder='Search'
					style="padding-right:10px"
					v-model="searchString"
					@keyup.enter="getPeakSyncData()"
					@click:clear="clearSearch()">
				</v-text-field>

				<!-- Filters -->
				<div style="padding-right:20px">
					<v-menu offset-y bottom left origin="top right" class="filter" :close-on-content-click="false" v-model="isOpened">
						<template v-slot:activator="{ on }" class="filter">
							<v-row justify="end" v-on="on">
								<v-badge color="error" style="z-index:999" overlap v-if="$store.state.filter"></v-badge>
								<v-btn class="btn" elevation="0" x-small>
									<v-icon>mdi-filter</v-icon>
									<span class="d-none d-md-inline">Filter</span>
									<v-icon style="cursor:pointer;">mdi-menu-down</v-icon>
								</v-btn>
							</v-row>
						</template>

						<v-list class="filter">
							<v-list-item class="filter pa-5">
								<v-list-item-title>
									<div @click="closeFilter()">
										<span style="width:100%">Status</span>
										<v-select filled solo class="btn"
											ref="filterStatus"
											v-model="selectedStatuses"
											:items="statuses"
											:item-text="item => item.id"
											:item-value="item => item.id"
											multiple small-chips hide-details
											:menu-props="{ offsetY: true  }">
											<template #selection="{ item }">
												<v-chip :color="item.color" dark>{{item.id}}</v-chip>
											</template>
										</v-select>
									</div>
								</v-list-item-title>
							</v-list-item>

							<v-divider/>

							<v-row style="width:100%;" align="center" justify="center" class="pa-4">
								<v-btn class="btn" elevation="0" style="width:48%;margin-right:4%" @click="clearFilter()">Clear Filter</v-btn>
								<v-btn class="btn green" elevation="0" dark style="width:48%;" @click="applyFilter()">Apply Filter</v-btn>
							</v-row>
						</v-list>
					</v-menu>
				</div>

				<v-btn class="btn ma-rgt" small elevation="0" @click="getPeakSyncData()">
					<v-icon>mdi-refresh-circle</v-icon> Refresh
				</v-btn>
			</v-row>
		</div>

		<div>
			<v-card class="tableCard" style="margin-top:10px">
				<v-data-table fixed-header hide-default-footer
					:headers="headers"
					:items="peakSyncData"
					:items-per-page="limit"
					:mobile-breakpoint="700"
					item-key="name"
					class="elevation-0"
					style="border-radius: 0" 
					>

					<template v-slot:item="{item}">
						<tr :class="rowStyle(item)" @click="showItemDetail(item)">
							<td>
								<span v-if="parseInt(item.entityid)">{{item.entityid}}</span>
							</td>
							<td>
								<span v-if="item.data.title?.de">{{item.data.title.de | truncate(70, '...')}}</span>
								<span >{{item.data.data[0].name.find(name => name.lang === 'de')?.value | truncate(70, '...')}}</span>
							</td>
							<td>{{item.created | formatDateTime}}</td>
							<td>{{item.updated | formatDateTime}}</td>
							<td align="center">{{item.version}}</td>
							<td>{{ item.error | truncate(50, '...') }}</td>
							<td><Status :status="item.status" /></td>
						</tr>
					</template>
				</v-data-table>

				<v-card class="tableFooter">
					<v-card-actions>
						<TablePaginator v-model="offset" :limit="limit" :total="count" @input="getPeakSyncData()" :results="peakSyncData" />
					</v-card-actions>
				</v-card>
			</v-card>
		</div>

		<!-- PEAK Sync Data Details -->
		<Dialog ref="peakSyncDataDialog"
			confirmLabel="Save Changes &amp; Run Sync"
			:confirm-handler="savePeakSyncData"
			cancelLabel="Cancel"
			:cancel-handler="onActionCancelled"
			:showClose="false"
      title="Import Data"
			:height="'90%'"
			:width="'60%'">
			<template #content>
				<PeakSyncData class="dialog-container" :peakSyncData="selectedItem"/>
			</template>
		</Dialog>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Status from '@/components/common/Status.vue'
import Dialog from '@/components/common/Dialog.vue'
import PeakSyncData from './PeakSyncData.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'PeakSyncView',
	components: { Loading, Alert, TablePaginator, Status, Dialog, PeakSyncData },
	mixins: [ Common ],

	data() {
		return {
			searchString: '',

			offset: 0,
			limit: 15,
			
			peakSyncData: [],
			selectedClientId: '',
			count: 0,
			originalItem: {},
			selectedItem: {},

			isOpened: false,
			statuses: [
					{id:'pending', color:'#ff9e21', dark:true},
					{id:'done', color:'#64c823', dark:true},
					{id:'error', color:'#f34545', dark:true},
			],
			selectedStatuses: this.$store.state.filter ? this.$store.state.filter.statuses : [],
		}
	},

	computed: {
		headers () {
			return [
				{ text: 'Peak ID', value: "client", sortable: false,  width: '5%' },
				{ text: 'Service Name', value: "title", sortable: false,  width: '35%' },
				{ text: 'Create Date', value: "created_at", sortable: false,  width: '10%' },
        { text: 'Update Date', value: "updated_at", sortable: false,  width: '10%' },
        { text: 'Version', value: "version", sortable: false, align: "center", width: '50px' },
				{ text: 'Error Message', value: "error_message", sortable: false,  width: '25%' },
				{ text: 'Status', value: "status", sortable: false,  width: '10%' }
			]
		},
	},

	async mounted() {
		this.selectedClientId = this.$store.state.selectedClientId
		await this.getPeakSyncData()
	},

	methods: {
    rowStyle(item) {
      return item.error > 0 ? 'error-row' : 'clear-row'
    },
		async goback() {
			await this.$store.commit('setFilter', null)
			this.$router.push('/peaksync')
		},
		getSelectedClient() {
			return this.peakSyncData?.[0]?.client
		},
		async applyFilter() {
			this.isOpened = false
			const filter = {
				statuses: this.selectedStatuses
			}
			await this.$store.commit('setFilter', filter)
			await this.getPeakSyncData()
		},
		async clearFilter() {
			this.isOpened = false
			this.selectedStatuses = []
			await this.$store.commit('setFilter', null)
			await this.getPeakSyncData()
		},
		closeFilter() {
			if (!this.dropdownOpen) {
				this.$refs.filterStatus.focus()
				this.dropdownOpen = true
			} else {
				this.$refs.filterStatus.blur()
				this.dropdownOpen = false
			}
		},
		async clearSearch() {
			this.searchString = ''
			this.offset = 0
			await this.getPeakSyncData()
		},
		async getPeakSyncData() {
			this.loading = true
			try {
				if (this.searchString?.length) {
					this.offset = 0
				} else {
					this.searchString = ''
				}

				let url = `/peak-sync/${this.selectedClientId}?skip=${this.offset}&limit=${this.limit}&filter=${JSON.stringify(this.$store.state.filter)}&searchString=${encodeURIComponent(this.searchString)}`
				
				const res = await this.$httpGet(url)
				this.peakSyncData = res.peakSyncData
				this.count = res.total
			}
			catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
    async showItemDetail(item) {
			try {
				this.originalItem = JSON.parse(JSON.stringify(item))
				this.selectedItem = JSON.parse(JSON.stringify(item))
				this.$refs.peakSyncDataDialog.show = true
			} catch (error) {
				this.showError(error)
			}
		},
		async savePeakSyncData() {
			this.loading = true
			try {
				const data = {
					peakSyncData: this.selectedItem
				}

				await this.$httpPut(`/import-data`, data)
				await this.getPeakSyncData()
			}
			catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		onActionCancelled() {
			this.selectedItem = JSON.parse(JSON.stringify(this.originalItem))
			this.$refs.peakSyncDataDialog.show = false
		}
	}
}
</script>
