import { render, staticRenderFns } from "./Disclosure.vue?vue&type=template&id=68dd0ffe&scoped=true&"
import script from "./Disclosure.vue?vue&type=script&lang=js&"
export * from "./Disclosure.vue?vue&type=script&lang=js&"
import style0 from "./Disclosure.vue?vue&type=style&index=0&id=68dd0ffe&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68dd0ffe",
  null
  
)

export default component.exports