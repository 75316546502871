<template>
	<v-container style="padding:0px" fluid>
		<div class="field left-border">
			<v-label>Can Manage Tags</v-label>
			<v-combobox
				chips multiple outlined dense hide-details append-icon=""
				v-model="selectedCanManageTags">
				<template v-slot:selection="{ attrs, item, select, selected }">
				<v-chip
					v-bind="attrs"
					:input-value="selected"
					close
					@click="select"
					@click:close="removeCanManageTag(item)">
					{{ item }}
				</v-chip>
				</template>
			</v-combobox>
		</div>

		<div class="field left-border">
			<v-label>Can See Tags</v-label>
			<v-combobox
				chips multiple outlined dense hide-details append-icon=""
				v-model="selectedCanSeeTags">
				<template v-slot:selection="{ attrs, item, select, selected }">
				<v-chip
					v-bind="attrs"
					:input-value="selected"
					close
					@click="select"
					@click:close="removeCanSeeTag(item)">
					{{ item }}
				</v-chip>
				</template>
			</v-combobox>
		</div>
	</v-container>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
	name: 'TagAssignment',
	mixins: [ Common ],
	props: {
		canManageTags: Array,
		canSeeTags: Array
	},
	data() {
		return {
			selectedCanManageTags: this.canManageTags?.length ? this.canManageTags : [],
			selectedCanSeeTags: this.canSeeTags?.length ? this.canSeeTags : [],
		}
	},
	watch: {
		selectedCanManageTags() {
			this.$emit('update-manage-tags', this.selectedCanManageTags)
		},
		selectedCanSeeTags() {
			this.$emit('update-see-tags', this.selectedCanSeeTags)
		}
	},
	methods: {
		removeCanManageTag(item) {
			this.selectedCanManageTags.splice(this.selectedCanManageTags.indexOf(item), 1)
			this.selectedCanManageTags = [...this.selectedCanManageTags]
		},	
		removeCanSeeTag(item) {
			this.selectedCanSeeTags.splice(this.selectedCanSeeTags.indexOf(item), 1)
			this.selectedCanSeeTags = [...this.selectedCanSeeTags]
		}
	},
}
</script>
