<template>
	<v-container class="main" fluid fill-width>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div class="toolbar">
			<v-row align="center">
				<v-text-field outlined dense clearable hide-details height="48"
					prepend-inner-icon="mdi-magnify"
					placeholder='Search'
					style="padding-right:10px"
					v-model="searchString"
					@keyup.enter="search()"
					@click:clear="clearSearch()">
				</v-text-field>
				<v-btn class="btn" small elevation="0" v-if="userMayEditFields()" @click="showNewClient()">
					<v-icon>mdi-plus-circle</v-icon>
					<span class="d-none d-md-inline">Add Client</span>
				</v-btn>
			</v-row>
		</div>

		<v-card class="tableCard" style="margin-top:10px">
			<v-data-table fixed-header hide-default-footer
				v-model="selected"
				:headers="headers"
				:items="clients"
				:items-per-page="limit"
				:mobile-breakpoint="700"
				item-key="name"
				class="elevation-0"
				style="border-radius: 0">

				<template v-slot:item="{item}">
          <tr @click="showClientDetail(item)">
            <td>{{item.fields.title.en}}</td>
            <td align="right">{{item.sys.updatedAt | formatDate}}</td>
          </tr>
        </template>
			</v-data-table>

			<v-card class="tableFooter">
				<v-card-actions>
					<TablePaginator v-model="offset" :limit="limit" :total="count" @input="getClients()" :results="clients" />
				</v-card-actions>
			</v-card>
		</v-card>

		<ClientSetupProgress ref="clientSetupProgress"
			:height="'90%'"
			:width="'1450px'">
		</ClientSetupProgress>	
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Status from '@/components/common/Status.vue'
import ClientSetupProgress from './ClientSetupProgress.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'ClientsView',
	components: { Loading, Alert, TablePaginator, Status, ClientSetupProgress },
	mixins: [ Common ],

	data() {
		return {
			searchString: this.$store.state.searchString ? this.$store.state.searchString : '',

			offset: this.$store.state.offset ? this.$store.state.offset : 0,
			limit: 15,
			
			clients: [],
			count: 0,
			selected: []
		}
	},

	computed: {
		headers () {
			return [
				{ text: 'Client Name', value: "title", sortable: false },
				{ text: 'Updated At', value: "updated_at", sortable: false, align:'right', width: '15%' },
			]
		},
	},

	mounted() {
		this.getClients()
	},

	methods: {
		async getClients() {
			this.loading = true
			try {
				await this.$store.commit('setOffset', this.offset)
				let url = `/clients?skip=${this.offset}&limit=${this.limit}&include=0&searchString=${encodeURIComponent(this.searchString)}`
				
				const res = await this.$httpGet(url)
				this.clients = res.clients
				this.count = res.total
			}
			catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		async showClientDetail(item) {
			try {
				this.loading = true
				const client = await this.$httpGet(`/client/${item.sys.id}`)
				await this.$store.commit('setSelectedClient', client)
				this.loading = false

				this.$router.push('/client-detail')
				
			} catch (error) {
				this.showError(error)
			}
		},
		showNewClient() {
			this.$refs.clientSetupProgress.show=true
		},
		onActionConfirm() {
			this.$refs.clientSetupProgress.show=false
		},
		onActionCancelled() {
			this.$refs.clientSetupProgress.show=false
		},
		async search() {
			await this.$store.commit('setSearchString', this.searchString)
			
			if (this.searchString?.length > 0) {
				this.offset = 0
				this.getClients()
			} else {
				this.clearSearch()
			}
		},
		async clearSearch() {
			this.searchString = '', this.offset = 0
			await this.$store.commit('setSearchString', '')
		
			await this.getClients()
		}
	}
}
</script>

