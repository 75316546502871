<template>
	<v-container class="main" fluid fill-width>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>
					&nbsp;<h2>{{client}} Contentful Sync Errors</h2>
					<v-spacer/>

					<!-- Filters -->
					<div style="padding-right:20px">
						<v-menu offset-y bottom left origin="top right" class="filter" :close-on-content-click="false" v-model="isOpened">
							<template v-slot:activator="{ on }" class="filter">
								<v-row justify="end" v-on="on">
									<v-badge color="error" style="z-index:999" overlap v-if="$store.state.filter"></v-badge>
									<v-btn class="btn" elevation="0" x-small>
										<v-icon>mdi-filter</v-icon>
										<span class="d-none d-md-inline">Filter</span>
										<v-icon style="cursor:pointer;">mdi-menu-down</v-icon>
									</v-btn>
								</v-row>
							</template>

							<v-list class="filter">
								<v-list-item class="filter pa-5">
									<v-list-item-title>
										<div @click="closeFilter()">
											<span style="width:100%">Content Type</span>
											<v-select filled solo class="btn"
												ref="filterContentType"
												v-model="selectedContentTypes"
												:items="contentTypes"
												multiple small-chips hide-details
												:menu-props="{ offsetY: true  }">
												<template #selection="{ item }">
													<v-chip>{{item}}</v-chip>
												</template>
											</v-select>
										</div>
									</v-list-item-title>
								</v-list-item>

								<v-divider/>

								<v-row style="width:100%;" align="center" justify="center" class="pa-4">
									<v-btn class="btn" elevation="0" style="width:48%;margin-right:4%" @click="clearFilter()">Clear Filter</v-btn>
									<v-btn class="btn green" elevation="0" dark style="width:48%;" @click="applyFilter()">Apply Filter</v-btn>
								</v-row>
							</v-list>
						</v-menu>
					</div>

					<v-btn class="btn ma-rgt" small elevation="0" @click="getSyncErrors()">
						<v-icon>mdi-refresh-circle</v-icon> Refresh
					</v-btn>
				</v-row>
			</div>
		</div>

		<v-card class="tableCard" style="margin-top:10px">
			<v-data-table fixed-header hide-default-footer disable-sort 
				:headers="headers"
				:items="syncErrors"
				:items-per-page="limit"
				class="elevation-0">

				<template v-slot:item="{item}">
					<tr>
						<td class="pa-5" valign="top">{{item.entry_id}}</td>
						<td class="pa-5" valign="top">{{item.content_type}}</td>
						<td class="pa-5">{{item.sync_failure_reason}}</td>
						<td class="pa-5" valign="top" style="text-align:right">{{item.updated_at | formatDateTime}}</td>
						<td class="pa-5" valign="top">
							<v-btn class="btn" elevation="0" x-small @click="retrySyncActive=true;retrySync(item)">
								<v-icon>mdi-refresh</v-icon>
								<span class="d-none d-md-inline">Retry Sync</span>
							</v-btn>
						</td>
					</tr>
				</template>
			</v-data-table>

			<v-card class="tableFooter">
				<v-card-actions>
					<TablePaginator v-model="offset" :limit="limit" :total="count" @input="getSyncErrors()" :results="syncErrors" />
				</v-card-actions>
			</v-card>
		</v-card>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'ContentfulSyncDetailView',
	components: { Loading, TablePaginator, Alert },
	mixins: [ Common ],
	props: {
		client: String,
		space: String,
		environment: String,
	},

	data() {
		return {
			offset: 0,
			limit: 15,
			count: 0,
			syncErrors: [],

			contentTypes: [],
			selectedContentTypes: [],
			isOpened: false,
			retrySyncActive: false
		}
	},

	computed: {
		headers () {
			return [
				{ text: 'Entry ID', value: 'entry_id', width:'30%', cellClass: 'truncate' },
				{ text: 'Content Type', value: 'content_type', width:'10%', cellClass: 'truncate' },
				{ text: 'Error', value: 'error', width:'60%%' },
				{ text: 'Updated At', value: "updated_at", sortable: false, align:'center', width: '15%' },
				{ text: '' },
			]
		},
	},

	mounted() {
		this.getSyncErrors()
	},

	methods: {
		async getSyncErrors() {
			this.loading = true

			try {
				let url = `/contentful-sync/errors/${this.space}/${this.environment}?skip=${this.offset}&limit=${this.limit}&filter=${JSON.stringify(this.$store.state.filter)}`
				
				const res = await this.$httpGet(url)
				this.syncErrors = res.entries
				this.count = res.total
				this.contentTypes = !this.contentTypes?.length ? res.contentTypes : this.contentTypes

				this.loading = false

			} catch (error) {
				this.loading = false

				if (error.response?.status === 401) this.$emit("show-login")
				if (error.response) {
					this.showError(error)
				}
			}
		},
		async retrySync(item) {
			if (this.retrySyncActive) {
				this.loading = true

				try {
					let url = `/contentful-sync/reset-error`

					const data = {
						space: this.space,
						environment: this.environment,
						entryId: item.entry_id,
						filter: this.$store.state.filter
					}
					
					const res = await this.$httpPost(url, data)
					
					this.syncErrors = res.entries
					this.count = res.total
					this.contentTypes = !this.contentTypes?.length ? res.contentTypes : this.contentTypes

					this.loading = false

				} catch (error) {
					this.loading = false

					if (error.response?.status === 401) this.$emit("show-login")
					if (error.response) {
						this.showError(error)
					}
				}
			}
		},
		async goback() {
			await this.$store.commit('setFilter', null)
			this.$router.push('/contentfulsync')
		},
		async applyFilter() {
			this.isOpened = false
			const filter = {
				contentTypes: this.selectedContentTypes
			}
			await this.$store.commit('setFilter', filter)
			await this.getSyncErrors()
		},
		async clearFilter() {
			this.isOpened = false
			this.selectedContentTypes = []
			await this.$store.commit('setFilter', null)
			await this.getSyncErrors()
		},
		closeFilter() {
			if (!this.dropdownOpen) {
				this.$refs.filterContentType.focus()
				this.dropdownOpen = true
			} else {
				this.$refs.filterContentType.blur()
				this.dropdownOpen = false
			}
		}
	}
}
</script>
