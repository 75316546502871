<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>
		
		<!-- toolbar -->
		<div class="pa-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>
					&nbsp;<h2>{{ this.client.fields.title.de }} Detail View</h2>
				</v-row>
			</div>
		</div>

		<SideBar>
			<div class="sidebar-block">
				<p class="sidebar-title">Actions</p>
				<div class="buttonBlock">
					<v-btn block class="btn blue mt-3" elevation="0" dark @click="upsertClient()">Save Changes</v-btn>
				</div>
			</div>

			<div class="sidebar-block" v-if="action!==Action.CREATE">
				<p class="sidebar-title">Info</p>
				<v-label v-if="client && client.sys">{{client.sys.id}}</v-label>
			</div>
		</SideBar>

		<!-- General Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="General Information" :expanded="false" :error="sectionMessage.generalInfo.error" :message="sectionMessage.generalInfo.message" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>Client Name <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required
						:hide-details="!clientNameError.length"
						v-model="client.fields.title.de"
						:error-messages="clientNameError"
					/>
				</div>

				<div class="field left-border">
					<v-label>Client ID <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required
						:hide-details="!clientIdError.length"
						v-model="client.fields.clientId.de"
						:error-messages="clientIdError"
					/>
				</div>

				<div class="field left-border">
					<v-label>Logo URL</v-label>
					<v-text-field outlined required hide-details
						v-model="client.fields.logoUrl.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Currency <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required
						:hide-details="!currencyError.length"
						v-model="client.fields.currency.de"
						:error-messages="currencyError"
					/>
				</div>

				<div class="field left-border">
					<v-row style="padding:10px">
						<v-label>Background Image</v-label>
						<v-spacer/>
						<v-btn v-if="isUpdateBackgroundImage" class="btn" elevation="0" dense @click="cancelUpload()">Cancel Upload</v-btn>
					</v-row>

					<MediaCard 
						v-if="!isUpdateBackgroundImage && client.fields.backgroundImage.de.fields"
						:media="client.fields.backgroundImage.de" 
						:canEdit="true" 
						:canSelect="false"
						:showTitle="false"
						:fullView="true"
						@show-media-update="showUpdateBackgroundImage()"
					/>
					<br/>
					<FileUpload 
						v-if="isUpdateBackgroundImage || !client.fields.backgroundImage.de.fields"
						:options="dropOptions" 
						ref="fileUpload" 
						@thumbnail-created="onThumbnailCreated" 
						@file-removed="cancelUpload"
					/>
				</div>
			</Disclosure>
		</div>

		<!-- Features -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Features" :expanded="false" :error="sectionMessage.features.error" :message="sectionMessage.features.message" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>Client Features</v-label>
					<v-row v-for="feature in clientFeatures" :key="feature.id" class="pa-3">
						<v-switch hide-details
							v-model="feature.selected"
							:label="feature.id" 
						/>
					</v-row>
				</div>

				<div class="field left-border">
					<v-label>Marketplace Settings</v-label>
					<v-row v-for="setting in marketplaceSettings" :key="setting.id" class="pa-3">
						<v-switch hide-details
							v-model="setting.enabled"
							:label="setting.id" 
						/>
					</v-row>
				</div>
				
				<div class="field left-border">
					<LinkField style="margin-top:30px;margin-bottom:30px" title="Service Provider Checklist" :value="serviceProviderChecklist" :def="schema.contentTypes.checklist" :filter="{contentType:'checklist',search:''}"/>
				</div>
			</Disclosure>
		</div>

		<!-- PEAK Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="PEAK Config" :expanded="false" :error="sectionMessage.peakConfig.error" :message="sectionMessage.peakConfig.message" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>Installation Id (SKISUP) <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required
						:hide-details="!installationIdError.length"
						v-model="client.fields.installationId.de"
						:error-messages="installationIdError"
					/>
				</div>

				<div class="field left-border">
					<v-label>MyServices Config Store ID</v-label>
					<v-text-field outlined required hide-details
						v-model="client.fields.configStoreId.de"
					/>
				</div>
				
				<div class="field left-border">
					<v-label>Shop Client ID <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required
						:hide-details="!shopClientIdError.length"
						v-model="client.fields.shopClientId.de"
						:error-messages="shopClientIdError"
					/>
				</div>

				<div class="field left-border">
					<v-label>Shop URL <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required
						:hide-details="!shopUrlError.length"
						v-model="client.fields.shopUrl.de"
						:error-messages="shopUrlError"
					/>
				</div>

				<div class="field left-border">
					<v-label>Api User</v-label>
					<v-text-field outlined required hide-details
						v-model="client.fields.peakApiUser.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Api User Password</v-label>
					<v-text-field outlined required hide-details
						type="password"
						v-model="client.fields.peakApiUserPassword.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Xmlrpc User</v-label>
					<v-text-field outlined required hide-details
						v-model="client.fields.xmlrpcUser.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Xmlrpc User Password</v-label>
					<v-text-field outlined required hide-details
						type="password"
						v-model="client.fields.xmlrpcPassword.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Kafka Topic (mandant only) <span class="mandatory">(required)</span></v-label>
					<v-combobox
						chips multiple outlined dense append-icon=""
						:hide-details="!kafkaTopicsError.length"
						:error-messages="kafkaTopicsError"
						v-model="client.fields.kafkaTopics.de">
						<template v-slot:selection="{ attrs, item, select, selected }">
						<v-chip
							v-bind="attrs"
							:input-value="selected"
							close
							@click="select"
							@click:close="removeKafkaTopic(item)">
							{{ item }}
						</v-chip>
						</template>
					</v-combobox>
				</div>
			</Disclosure>
		</div>

		<!-- Contentful Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Contentful Config" :expanded="false" :error="sectionMessage.contentfulConfig.error" :message="sectionMessage.contentfulConfig.message" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>Contentful Space <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required
						:hide-details="!contentfulSpaceError.length"
						v-model="client.fields.contentfulSpace.de"
						:error-messages="contentfulSpaceError"
					/>
				</div>

				<div class="field left-border">
					<v-label>Contentful Environment <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required
						:hide-details="!contentfulEnvironmentError.length"
						v-model="client.fields.contentfulEnvironment.de"
						:error-messages="contentfulEnvironmentError"
					/>
				</div>
			
			</Disclosure>
		</div>

		<!-- Contingent Service Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Contingent Service Config" :expanded="false" :error="sectionMessage.contingentServiceConfig.error" :message="sectionMessage.contingentServiceConfig.message" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>Contingent Service URL <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required
						:hide-details="!contingentServiceUrlError.length"
						v-model="client.fields.contingentServiceUrl.de"
						:error-messages="contingentServiceUrlError"
					/>
				</div>

				<div class="field left-border">
					<v-label>Contingent Service User <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required
						:hide-details="!contingentServiceUserError.length"
						v-model="client.fields.contingentServiceUser.de"
						:error-messages="contingentServiceUserError"
					/>
				</div>
				
				<div class="field left-border">
					<v-label>Contingent Service Password <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required
						:hide-details="!contingentServicePasswordError.length"
						v-model="client.fields.contingentServicePassword.de"
						:error-messages="contingentServicePasswordError"
					/>
				</div>
			</Disclosure>
		</div>

		<!-- Ticket Service Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Ticket Service Config" :expanded="false" :error="sectionMessage.ticketServiceConfig.error" :message="sectionMessage.ticketServiceConfig.message" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>Ticket Service URL</v-label>
					<v-text-field outlined hide-details
						v-model="client.fields.ticketServiceUrl.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Ticket Service User</v-label>
					<v-text-field outlined hide-details
						v-model="client.fields.ticketServiceUser.de"
					/>
				</div>
				
				<div class="field left-border">
					<v-label>Ticket Service Password</v-label>
					<v-text-field outlined hide-details
						v-model="client.fields.ticketServicePassword.de"
					/>
				</div>

				<div class="field left-border">
					<v-label>Ticket Service Tenant</v-label>
					<v-text-field outlined hide-details
						v-model="client.fields.ticketServiceTenant.de"
					/>
				</div>

				<div class="field left-border">
					<v-switch
						v-model="client.fields.isTicketServiceProxy.de"
						label="Client uses the Proxy Ticket Service" 
					/>
				</div>
			</Disclosure>
		</div>

		<!-- James Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="James Config" :expanded="false" :error="sectionMessage.jamesConfig.error" :message="sectionMessage.jamesConfig.message" :lock="!userMayEditFields()">
				<div class="field left-border">
					<v-label>JAMES Customer ID <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required
						:hide-details="!jamesCustomerIdError.length"
						v-model="client.fields.jamesCustomerId.de"
						:error-messages="jamesCustomerIdError"
					/>
				</div>
			</Disclosure>
		</div>

		<!-- Sales Channels -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Sales Channels" :expanded="false" :error="sectionMessage.salesChannels.error" :message="sectionMessage.salesChannels.message" :lock="!userMayEditFields()">
				<div> 
					<LinksField style="margin-top:30px;margin-bottom:30px;width:100%" :value="salesChannels" :max="20" :def="schema.contentTypes.salesChannel" :filter="{contentType:'salesChannel',search:''}"/>
				</div>
			</Disclosure>
		</div>

		<!-- Reseller Categories -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Reseller Categories" :expanded="false" :error="sectionMessage.resellerCategories.error" :message="sectionMessage.resellerCategories.message" :lock="!userMayEditFields()">
				<div> 
					<LinksField style="margin-top:30px;margin-bottom:30px;width:100%" :value="resellerCategories" :max="20" :def="schema.contentTypes.resellerCategory" :filter="{contentType:'resellerCategory',search:''}"/>
				</div>
			</Disclosure>
		</div>

		<!-- Product Categories -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Product Categories" :expanded="false" :error="sectionMessage.productCategories.error" :message="sectionMessage.productCategories.message" :lock="!userMayEditFields()">
				<div> 
					<LinksField style="margin-top:30px;margin-bottom:30px" :value="productCategories" :max="20" :def="schema.contentTypes.productCategory" :filter="{contentType:'productCategory',search:''}"/>
				</div>
			</Disclosure>
		</div>

		<!-- Tax Classes -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Tax Classes" :expanded="false" :error="sectionMessage.taxClasses.error" :message="sectionMessage.taxClasses.message" :lock="!userMayEditFields()">
				<div> 
					<LinksField style="margin-top:30px;margin-bottom:30px" :value="taxClasses" :max="20" :def="schema.contentTypes.taxClass" :filter="{contentType:'taxClass',search:''}"/>
				</div>
			</Disclosure>
		</div>

		<!-- Ticket Types -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Ticket Types" :expanded="false" :error="sectionMessage.ticketTypes.error" :message="sectionMessage.ticketTypes.message" :lock="!userMayEditFields()">
				<div> 
					<LinksField style="margin-top:30px;margin-bottom:30px" :value="ticketTypes" :max="20" :def="schema.contentTypes.ticketType" :filter="{contentType:'ticketType',search:''}"/>
				</div>
			</Disclosure>
		</div>

		<!-- Reductions -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Reductions" :expanded="false" :error="sectionMessage.reductions.error" :message="sectionMessage.reductions.message" :lock="!userMayEditFields()">
				<div> 
					<LinksField style="margin-top:30px;margin-bottom:30px" :value="reductions" :max="20" :def="schema.contentTypes.reduction" :filter="{contentType:'reduction',search:''}"/>
				</div>
			</Disclosure>
		</div>

	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import SideBar from "@/components/common/SideBar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import MediaCard from '@/components/common/MediaCard.vue'
import FileUpload from '@/components/common/FileUpload'
import Common from '@/mixins/Common.vue'
import LinksField from '@/components/entry/LinksField.vue'
import LinkField from '@/components/entry/LinkField.vue'
import { Action } from '@/plugins/enum.js'
import _ from 'lodash'

import { Client } from "@/models/client.ts"

export default {
	name: "ClientDetailView",
	components: { Loading, SideBar, Alert, Disclosure, MediaCard, FileUpload, LinksField, LinkField },
	mixins: [ Common ],

	props: {
		initialAction: { type: String }
	},

	data() {
		return {
			Action: Action,
			action: this.initialAction,

			salesChannels: [],
			resellerCategories: [],
			taxClasses: [],
			ticketTypes: [],
			reductions: [],
			defaultAssignments: [],
			productCategories: [],
			serviceProviderChecklist: {},
			coreConfig: {},
			clientFeatures: [],
			marketplaceSettings: [],
			
			originalBackgroundImage: {},
			isUpdateBackgroundImage: false,
			newBackgroundImage: {},
			dropOptions: {
				acceptedFiles: '.png,.jpg,.jpeg',
				addRemoveLinks: true,
				maxFilesize: 10,
				maxFiles: 1
			},
			
			sectionMessage: {
				generalInfo: {error:false,warning:false,message:''},
				features:  {error:false,warning:false,message:''},
				peakConfig: {error:false,warning:false,message:''},
				contentfulConfig: {error:false,warning:false,message:''},
				contingentServiceConfig: {error:false,warning:false,message:''},
				ticketServiceConfig: {error:false,warning:false,message:''},
				jamesConfig: {error:false,warning:false,message:''},
				productCategories: {error:false,warning:false,message:''},
				salesChannels: {error:false,warning:false,message:''},
				resellerCategories: {error:false,warning:false,message:''},
				taxClasses: {error:false,warning:false,message:''},
				ticketTypes: {error:false,warning:false,message:''},
				reductions: {error:false,warning:false,message:''},
				defaultAssignments: {error:false,warning:false,message:''},
				contactTracing:  {error:false,warning:false,message:''}
			},

			client: new Client()
		}
	},
	
	computed: {
		clientNameError() {
			return this.runValidation && !this.client.fields.title.de.length ? 'Client Name is required ' : ''
		},
		clientIdError() {
			return this.runValidation && !this.client.fields.clientId.de.length ? 'Client ID is required' : ''
		},
		shopClientIdError() {
			return this.runValidation && !this.client.fields.shopClientId.de.length ? 'Shop Client ID is required' : ''
		},
		shopUrlError() {
			return this.runValidation && !this.client.fields.shopUrl.de.length ? 'Shop URL is required' : ''
		},
		currencyError() {
			return this.runValidation && !this.client.fields.currency.de.length ? 'Currency is required' : ''
		},
		contentfulSpaceError() {
			return this.runValidation && !this.client.fields.contentfulSpace.de.length ? 'Contentful Space is required' : ''
		},
		contentfulEnvironmentError() {
			return this.runValidation && !this.client.fields.contentfulEnvironment.de.length ? 'Contentful Environment is required' : ''
		},
		contingentServiceUrlError() {
			return this.runValidation && !this.client.fields.contingentServiceUrl.de.length ? 'Contingent Service URL is required' : ''
		},
		contingentServiceUserError() {
			return this.runValidation && !this.client.fields.contingentServiceUser.de.length ? 'Contingent Service User is required' : ''
		},
		contingentServicePasswordError() {
			return this.runValidation && !this.client.fields.contingentServicePassword.de.length ? 'Contingent Service Password is required' : ''
		},
		jamesCustomerIdError() {
			return this.runValidation && !this.client.fields.jamesCustomerId.de.length ? 'JAMES Customer ID is required' : ''
		},
		installationIdError() {
			return this.runValidation && !this.client.fields.installationId.de.length ? 'Installation Id (SKISUP) is required' : ''
		},
		kafkaTopicsError() {
			return this.runValidation && !this.client.fields.kafkaTopics.de.length ? 'Kafka Topic is required' : ''
		},
		contactTracingError() {
			return ''
		}
	},

	async mounted() {
		if (!this.schema) {
			await this.getSchema()
		}
		await this.getClientDetails()
	},

	methods: {
		async getClientDetails() {
			if (this.$store.state.selectedClient) {
				this.client = _.merge(this.client, this.$store.state.selectedClient)
				
				this.salesChannels = this.client?.fields?.salesChannels?.de
				this.salesChannels?.sort((a, b) => { return a?.fields?.title?.de?.localeCompare(b?.fields?.title?.de) })

				this.resellerCategories = this.client?.fields?.resellerCategories?.de
				this.resellerCategories?.sort((a, b) => { return a?.fields?.title?.de?.localeCompare(b?.fields?.title?.de) })

				this.taxClasses = this.client?.fields?.taxClasses?.de
				this.taxClasses?.sort((a, b) => { return a?.fields?.title?.de?.localeCompare(b?.fields?.title?.de) })

				this.ticketTypes = this.client?.fields?.ticketTypes?.de
				this.ticketTypes?.sort((a, b) => { return a?.fields?.title?.de?.localeCompare(b?.fields?.title?.de) })

				this.reductions = this.client?.fields?.reductions?.de
				this.reductions?.sort((a, b) => { return a?.fields?.title?.de?.localeCompare(b?.fields?.title?.de) })

				this.defaultAssignments = this.client?.fields?.defaultAssignments?.de

				this.productCategories = this.client?.fields?.productCategories?.de
				this.productCategories?.sort((a, b) => { return a?.fields?.title?.de?.localeCompare(b?.fields?.title?.de) })

				this.originalBackgroundImage = this.client?.fields?.backgroundImage?.de ? JSON.parse(JSON.stringify(this.client.fields.backgroundImage.de)) : {}
				this.serviceProviderChecklist = this.client?.fields?.serviceProviderChecklist?.de

				this.coreConfig = await this.$httpGet(`/core-config`)

				this.clientFeatures = []
				if (this.coreConfig.fields.features?.de?.length) {
					for (const feature of this.coreConfig.fields.features.de) {
						const clientFeature = this.client.fields.features?.de?.find(x => x?.id === feature)

						if (!clientFeature) {
							this.clientFeatures.push({
								id: feature,
								selected: false
							})
						} else {
							this.clientFeatures.push({
								id: feature,
								selected: clientFeature?.status === 'enabled' ? true : false
							})
						}
					}
				}
				
				this.marketplaceSettings = []
				if (this.coreConfig.fields.marketplaceSettings?.de?.length) {
					for (const setting of this.coreConfig.fields.marketplaceSettings.de) {
						const marketplaceSetting = this.client.fields.marketplaceSettings?.de?.find(x => x?.id === setting)

						if (!marketplaceSetting) {
							console.log("dont have")
							this.marketplaceSettings.push({
								id: setting,
								enabled: false
							})
						} else {
							this.marketplaceSettings.push({
								id: setting,
								enabled: marketplaceSetting?.enabled
							})
						}
					}
				}

				this.validateClientDetails()
			}
		},
		async upsertClient() {
			const isValid = this.validateClientDetails()
			if (!isValid) return

			this.loading = true
			
			try {
				if (Object.keys(this.newBackgroundImage).length > 0) {
					this.client.fields.backgroundImage.de = this.newBackgroundImage
				}
				
				const data = {
					client: this.client,
				}

				//this.client = await this.$httpPut(`/client`, data)
				
				this.successTitle = 'UPDATE CLIENT'
				this.successDetail = 'Client updated successfully'

			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		validateClientDetails() {
			this.runValidation = true
			let isValid = true

			if (this.clientNameError.length || 
					this.clientIdError.length || 
					this.shopClientIdError.length) {

				this.sectionMessage.generalInfo.error = true
				this.sectionMessage.generalInfo.message = 'Please enter the required information'
				isValid = false
			} else {
				this.sectionMessage.generalInfo.error = false
			}

			if (isValid === false) {
				this.errorTitle = 'ERROR'
				this.errorDetail = 'Please enter the required information'
			}
			
			return isValid
		},
		showUpdateBackgroundImage() {
			this.isUpdateBackgroundImage = true
		},
		onThumbnailCreated(file) {
			this.newBackgroundImage = {
				sys: {
					id: file.upload.uuid
				},
				fields:{
					title: {},
					altText: {},
					description: {},
					file: file
				}
			}

			for (const locale of this.locales) {
				this.newBackgroundImage.fields.title[locale.code] = `MYS Background - ${this.client.fields.title.de}`
				this.newBackgroundImage.fields.altText[locale.code] = `MYS Background- ${this.client.fields.title.de}`
				this.newBackgroundImage.fields.description[locale.code] = `MYS Background - ${this.client.fields.title.de}`
			}
		},
		cancelUpload() {
			this.newBackgroundImage = {},
			this.isUpdateBackgroundImage = false
			this.client.fields.backgroundImage.de = JSON.parse(JSON.stringify(this.originalBackgroundImage))
		},
		goback() {
			this.$router.push('/clients')
		}
	}
}
</script>

<style scoped>
.link-field { border: thin #dddddd solid; border-radius: 10px; margin-top: 10px; margin-bottom: 10px; }
.header { color: gray; font-size: smaller; text-transform: capitalize; }
.pickerCard { border-radius: 10px; border: 0; padding: 15px; }
.addEntry { border: 1px dashed #ccc; padding: 20px; border-radius: 5px; text-align: center; }
</style>