<template>
  <div class="file-upload__container">
    <div ref="fileUploadElement" class="file-upload__dropzone dropzone">
      <div class="dz-message">
        <slot>
          <v-icon size="64">mdi-cloud-upload</v-icon>
          <p>Upload File</p>
        </slot>
      </div>
    </div>
    <div class="file-upload__error-message" v-show="errorMessagesList.length > 0" v-html="errorMessages"></div>
  </div>
</template>

<script>
// See https://www.dropzone.dev/js/
import {Dropzone} from 'dropzone'
import 'dropzone/dist/dropzone.css'


export default {
  name: 'FileUpload',
  emits: ['file-added', 'files-added', 'thumbnail-created', 'file-removed'],
  props: {
    uploadText: {
      type: String,
      default: 'text.uploadFileDescription'
    },
    options: {
      type: Object,
      default: () => ({})
    }
  },
 data() {
		return {
      errorMessagesList: [],
    }
 },
  computed: {
    dropzoneOptions() {
      let options = {
        url: 'localhost',
        autoProcessQueue: false,
        previewTemplate: this.template(),
        addRemoveLinks: true,
        dictMaxFilesExceeded: 'Max Files Exceeded'
      }

      Object.keys(this.options).forEach(function (key) {
        options[key] = this.options[key];
        if (key === 'maxFilesize') {
          options['dictFileTooBig'] = ('File Size Error', {size: this.options[key]})
        } else if (key === 'acceptedFiles') {
          options['dictInvalidFileType'] = ('File Type Error', {types: this.options[key]})
        }
      }, this)

      return options
    },
    errorMessages() {
      return this.errorMessagesList.join('<br/>')
    }
  },
  methods: {
    template() {
      return `<div class="dz-preview dz-file-preview">
                <div class="dz-image"><img data-dz-thumbnail /></div>
                <div class="dz-details">
                <div class="dz-filename"><span data-dz-name></span></div>
                  <div class="dz-size"><span data-dz-size></span></div>
                  <div class="rm-btn__container">
                    <button class="dz-remove custom" type="button" data-dz-remove>
                      <i class="v-icon mdi mdi-delete theme--light" style="color:red"></i>
                    </button>
                  </div>
                </div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark">
                  <svg
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.2071 29.7929L14.2929 25.7071C14.6834 25.3166 15.3166 25.3166 15.7071 25.7071L21.2929 31.2929C21.6834 31.6834 22.3166 31.6834 22.7071 31.2929L38.2929 15.7071C38.6834 15.3166 39.3166 15.3166 39.7071 15.7071L43.7929 19.7929C44.1834 20.1834 44.1834 20.8166 43.7929 21.2071L22.7071 42.2929C22.3166 42.6834 21.6834 42.6834 21.2929 42.2929L10.2071 31.2071C9.81658 30.8166 9.81658 30.1834 10.2071 29.7929Z"
                    />
                  </svg>
                </div>
                <div class="dz-error-mark">
                  <svg
                    width="54"
                    height="54"
                    viewBox="0 0 54 54"
                    fill="white"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M26.2929 20.2929L19.2071 13.2071C18.8166 12.8166 18.1834 12.8166 17.7929 13.2071L13.2071 17.7929C12.8166 18.1834 12.8166 18.8166 13.2071 19.2071L20.2929 26.2929C20.6834 26.6834 20.6834 27.3166 20.2929 27.7071L13.2071 34.7929C12.8166 35.1834 12.8166 35.8166 13.2071 36.2071L17.7929 40.7929C18.1834 41.1834 18.8166 41.1834 19.2071 40.7929L26.2929 33.7071C26.6834 33.3166 27.3166 33.3166 27.7071 33.7071L34.7929 40.7929C35.1834 41.1834 35.8166 41.1834 36.2071 40.7929L40.7929 36.2071C41.1834 35.8166 41.1834 35.1834 40.7929 34.7929L33.7071 27.7071C33.3166 27.3166 33.3166 26.6834 33.7071 26.2929L40.7929 19.2071C41.1834 18.8166 41.1834 18.1834 40.7929 17.7929L36.2071 13.2071C35.8166 12.8166 35.1834 12.8166 34.7929 13.2071L27.7071 20.2929C27.3166 20.6834 26.6834 20.6834 26.2929 20.2929Z"
                    />
                  </svg>
                </div>
              </div>`
    },
    /**
     * If an uploaded file is invalid or has an error, we'll show the error message for 5 sec.
     * @param {string} msg
     */
    addErrorMessage(msg) {
      if (!this.errorMessagesList.includes(msg)) {
        this.errorMessagesList.push(msg)
      }
      let vm = this
      setTimeout(function () {
        for (let idx = 0; idx < vm.errorMessagesList.length; idx++) {
          if (vm.errorMessagesList[idx] === msg) {
            vm.errorMessagesList.splice(idx, 1)
            break
          }
        }
      }, 5000);
    }
  },
  mounted() {
    let vm = this

    this.dropzone = new Dropzone(
        this.$refs.fileUploadElement,
        this.dropzoneOptions
    )

    // Events
    this.dropzone.on('addedfile', (file) => {
      vm.$emit('file-added', file)
    })

    this.dropzone.on('addedfiles', (files) => {
      vm.$emit('files-added', files)
    })

    /**
     * Generates the thumbnail.
     * Info: file.dataURL is generated within this function.
     *       Function will be executed even if the file has an error.
     */
    this.dropzone.on('thumbnail', (file) => {
      if (file.status !== 'error') {
        vm.$emit('thumbnail-created', file)
      }
    })

    this.dropzone.on('removedfile', (file) => {
      vm.$emit('file-removed', file)
    })

    /**
     * Called whenever an error occurs.
     * It is not possible to upload an erroneous file because we immediately remove it.
     */
    this.dropzone.on('error', (file, response) => {
      this.dropzone.removeFile(file)
      this.addErrorMessage(response)
    })
  }
}
</script>

<style scoped lang="scss">
.file-upload {
  &__container {
    width: 100%;
    position: relative;
  }

  &__dropzone {
    font-family: 'Inter', sans-serif;
    border: 2px dashed #cccccc;
    min-height: 200px;

    .dz-error-mark {
      background: red;
      border-radius: 5px;
    }
  }

  &__error-message {
    color: #f34545
  }
}
</style>

<style lang="scss">
// Overwrite dropzone.js styles
.dropzone {
  .dz-preview {
    .dz-details {
      padding: 12px 8px !important;

      .dz-filename {
        margin-bottom: 8px;
        font-size: 16px;
      }

      .dz-size {
        margin-bottom: 0 !important;
        font-size: 13px !important;
      }

      .rm-btn__container {
        display: flex;
        justify-content: center;
        margin-top: 12px;

        .v-icon {
          cursor: pointer
        }
      }
    }

    .dz-remove {
      background: linear-gradient(0deg, #efefef 0%, #ffffff 100%) !important;
      border: thin #dddddd solid !important;
      padding: 5px;
      border-radius: 5px;

      &:hover {
        text-decoration: none !important;
      }

      &:not(.custom) {
        display: none !important;
      }
    }
  }
}
</style>
