<template>
    <div class="navbar">
      <v-row align="center" class="pa-3">
        <v-row align="center" class="pointer pa-3" @click="showDashboard()">
          <v-img src="@/assets/icons/app-icon-toolbox-dark.svg" class="logo-svg"/>
          <h3>MyServices Toolbox</h3>
          <span v-if="appName && appName!==''">
            <h3>&nbsp;- {{appName}}</h3> 
          </span>
        </v-row>
        <v-spacer/>

        <!-- Profile Menu -->
        <div style="margin-right: 10px" v-if="haveUser">
          <v-menu offset-y offset-x style="z-index:1000">
            <template v-slot:activator="{ on }">
              <v-row style="width:75px" align="center" justify="center" v-on="on">
                <a href="#">
                  <div class="aui-avatar aui-avatar-small">
                    <div class="aui-avatar-inner">
                      <img src="@/assets/icons/icon-avatar.svg" style="cursor:pointer"/>
                    </div>
                  </div>
                </a>
              </v-row>
            </template>

            <v-list style="width:300px">
              <v-list-item class="accountAction" style="cursor: default;">
                <v-list-item-title>
                  <v-icon>mdi-account</v-icon>
                  {{ userEmail }}
                </v-list-item-title>
              </v-list-item>

              <v-divider />
              <v-list-item style="cursor:pointer" @click="logout()">
                <v-list-item-title><v-icon>mdi-lock</v-icon> Logout</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-row>
		</div>
</template>

<script>
import Common from '@/mixins/Common.vue'

export default {
  name: 'NavBar',
	mixins: [ Common ],

  computed: {
    errorMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.errorTitle + ' </span><br> ' + this.errorDetail
    },
    successMessages() {
      return ' <span style="font-size:14pt;font-weight:bold;"> ' + this.successTitle + ' </span><br> ' + this.successDetail
    },
    currentPasswordErrors() {
      return ''
    },
    passwordErrors() {
      return ''
    },
    repeatPasswordErrors() {
      return ''
    },
    appName() {
      return this.$store.state.selectedAppComponent?.fields?.title?.en ? this.$store.state.selectedAppComponent.fields.title.en : ''
    },
    haveUser() {
      return this.$store.state.loggedInUser?.fields ? true : false
    },
    userEmail() {
      return this.$store.state.loggedInUser?.fields?.email?.de
    }
  },

  methods: {
    async logout() {
      await this.$store.commit('clear')
      this.$emit("show-login")
    },

    showDashboard() {
      this.$emit("show-dashboard")
    }
	},
}
</script>

<style scoped>
.aui-avatar.aui-avatar-small {
  --aui-avatar-size: 36px;
}
.aui-avatar {
  --aui-avatar-size: 32px;
  box-sizing: border-box;
  display: inline-block;
  position: relative;
  vertical-align: text-bottom;
}
.aui-avatar-inner {
  display: flex;
  justify-content: center;
  align-content: center;
  height: var(--aui-avatar-size);
  max-width: var(--aui-avatar-size);
  max-height: var(--aui-avatar-size);
  width: var(--aui-avatar-size);
  border-radius: 100%;
  overflow: hidden;
}

.v-list-item__title { font-size: 12pt !important; }
.v-list-item__title .v-icon { margin-right: 5px; }

.accountAction { background-color: #fafafa; }
.accountAction .v-list-item__title { font-size: 12px !important; }

.divider {
  border-color: #b4c3ca !important;
  border-width: 0.5px !important;
  opacity: 1 !important;
}
</style>