<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading v-if="loading" :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div class="pa-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>&nbsp;
					
					<v-spacer/>

					<!-- Add New Translation -->
					<v-btn class="btn" small elevation="0" @click="showAddTranslation()">
						<v-icon>mdi-plus-circle</v-icon>
						<span class="d-none d-md-inline">Add Translation</span>
					</v-btn>
				</v-row>
			</div>
		</div>
		
		<SideBar>
			<div class="sidebar-block">
				<p class="sidebar-title">Actions</p>
				<div class="buttonBlock">
					<v-btn block class="btn green" elevation="0" dark 
						:disabled="!text[selectedLocale.code]"
						@click="updateTranslations()">
						<v-icon>mdi-check</v-icon>Save Changes
					</v-btn>
				</div>
			</div>

			<div class="sidebar-block">
				<p class="sidebar-title">Applications</p>
				<v-select outlined dense hide-details class="mt-3"
					v-model="selectedApplication"
					:items="applications"
					:item-text="application => application.fields.title.en"
					:item-value="application => application.sys.id" 
					@change="getTranslationsForApp()"
				/>
			</div>
			
			<div class="sidebar-block">
				<p class="sidebar-title">Languages</p>
				<v-select outlined dense required hide-details class="mt-3"
					v-model="selectedLocale"
					:items="locales"
					:item-text="locale => locale.name"
					:item-value="locale => locale" 
				/>
			</div>
		</SideBar>

		<!-- Translations -->
		<div class="pa-3" style="width: 100%">
			<Disclosure :title="`${selectedLocale.name} Translations`" :expanded="true" v-if="text[selectedLocale.code]">
				<div class="field left-border" v-for="objKey in Object.keys(text[selectedLocale.code])" :key="objKey">
					<v-row style="padding-left:12px;padding-right:12px">
						<v-label>{{objKey}}</v-label>
						<v-spacer/>
						<v-btn class="btn-sml" elevation="0" @click="confirmDelete(objKey)"><v-icon color="red">mdi-delete</v-icon></v-btn>
					</v-row>
					<v-textarea outlined required hide-details rows="2"
						v-model="text[selectedLocale.code][objKey]"
					/>	
				</div>
			</Disclosure>

			<v-card v-if="!text[selectedLocale.code]" fluid flat width="100%" height="100%" style="padding:20px"> 
				There are no translations for the selected application or language
			</v-card>
		</div>

		<!-- Add Translation -->
		<Dialog ref="addTranslationDialog"
			confirmLabel="Add Translation"
			cancelLabel="Cancel"
			:confirm-handler="validateTranslation"
			:cancel-handler="onActionCancelled"
			:showClose="false"
			title="Add Translation"
			:height="'900px'"
			:width="'800px'">
			<template #content>
				<div class="pa-5" style="width:100%">
					<div class="field left-border">
						<v-label>Text Label <span class="mandatory">(required)</span></v-label>
						<v-text-field 
							outlined dense 
							:hide-details="textLabelError.length===0"
							:error-messages="textLabelError"
							v-model="newText.label" 
							@keypress="validJsonKey($event)"
						/>
					</div>

					<v-row v-for="locale of locales" :key="locale.code" style="padding-left:12px">
						<div class="field left-border">
							<v-label>{{locale.name}} Translation</v-label>
							<LanguageFlag v-model="locale.code" class="flag"/>
							<v-textarea outlined required hide-details style="width:744px" rows="2"
								v-model="newText.values[locale.code]"
							/>
						</div>
					</v-row>
				</div>
			</template>
		</Dialog>

		<!-- Confirm Delete -->
		<Dialog ref="deleteDialog"
			confirmLabel="Delete"
			cancelLabel="Cancel"
			:confirm-handler="deleteTranslation"
			:cancel-handler="onActionCancelled"
			:showClose="false"
      title="Delete Translation"
			:height="'300px'"
			:width="'600px'">
			<template #content>
				<v-row justify="center" align="center" class="pa-10" width="100%">
					<img src="@/assets/icons/icon-warning.svg" style="width:32px;height:32px"/>&nbsp;&nbsp;&nbsp;
					<span class="title">Are you sure you want to delete this translation?<br/>This action is irreversible.</span>
				</v-row>
			</template>
		</Dialog>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import SideBar from "@/components/common/SideBar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import Dialog from '@/components/common/Dialog.vue'
import { Action } from '@/plugins/enum.js'

export default {
	name: "TranslationsView",
	components: { Loading, SideBar, Alert, Disclosure, LanguageFlag, Dialog },
	mixins: [ Common ],

	data() {
		return {
			Action: Action,

			applications: [],
			selectedApplication: 'CORE',
			
			existingKeys: [],
			selectedObjKey: '',
			originalText: {},
			text: {},
			newText: {
				label: '',
				values: {}
			}
		}
	},

	async mounted() {
		this.loading = true
		this.getApplications()
		this.getTranslationKeys()
		await this.getTranslationsForApp()
		this.loading = false
	},

	computed: {
		textLabelError() {
			if (this.runValidation && !this.newText.label.length) {
        		return 'Text Label is required'
      		} else if (this.existingKeys.find(x => x === this.newText.label)) {
				return 'Text Label is already used'
			}
			return ''
		}
	},

	methods: {
		validJsonKey(evt) {
			evt = (evt) ? evt : window.event
			const validChars = new RegExp("^[a-zA-Z0-9]*$") 
			if (!validChars.test(evt.key)) {
				evt.preventDefault()
				return false
			}
			return true
		},
		goback() {
			this.$router.push('/dashboard')
		},
		showAddTranslation() {
			this.$refs.addTranslationDialog.show = true
			this.newText = {label:'',values:{}}
		},
		onActionCancelled() {
			this.$refs.addTranslationDialog.show = false
		},
		confirmDelete(objKey) {
			this.selectedObjKey = objKey
			this.$refs.deleteDialog.show = true
		},
		async getApplications() {
			try {
				const res = await this.$httpGet(`/applications?include=0&limit=1000`)
				this.applications = res.applications

				if (this.applications?.length) {
					this.applications = this.applications.filter(x => x.fields.userRoles.de.includes('engineer') === false)
				}
				this.applications.sort((a, b) => (a.fields.title.en > b.fields.title.en) ? 1 : -1)
				this.applications.unshift({sys:{id:'CORE'},fields:{title:{en:'CORE'}}})
			} catch (error) {
				this.showError(error)
			}
		},
		async getTranslationKeys() {
			try {
				this.existingKeys = await this.$httpGet(`/translationKeys`)
			} catch (error) {
				this.showError(error)
			}
		},
		async getTranslationsForApp() {
			this.loading = true
			const translations = await this.$httpGet(`/translations/${this.selectedApplication}`)
			
			if (translations?.length) {
				for (const translation of translations) {
					this.originalText[translation.locale] = translation.translationtext
				}
			} else {
				this.originalText = {}
			}

			this.text = JSON.parse(JSON.stringify(this.originalText))
			this.loading = false
		},
		async updateTranslations() {
			this.loading = true
			let data = []

			for (const locale of this.locales) {
				data.push({
					locale: locale.code,
					appComponentId: this.selectedApplication,
					translationText: this.text[locale.code]
				})
			}

			try {
				await this.$httpPut(`/translations`, data)
				
				this.successTitle = 'UPDATE TRANSLATIONS'
				this.successDetail = 'Translations updated successfully'

				await this.getTranslationsForApp()
			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		async addTranslation() {
			this.loading = true
			let data = []

			for (const locale of this.locales) {
				if (!this.text[locale.code]) {
					this.text[locale.code] = {}
				}
				
				this.text[locale.code][this.newText.label] = this.newText.values[locale.code] ? this.newText.values[locale.code] : ''
				
				data.push({
					locale: locale.code,
					appComponentId: this.selectedApplication,
					translationText: this.text[locale.code]
				})
			}

			try {
				await this.$httpPut(`/translations`, data)
				
				this.successTitle = 'CREATE TRANSLATION'
				this.successDetail = 'Translation created successfully'

				await this.getTranslationsForApp()
				
				this.existingKeys.push(this.newText.label)
				this.runValidation = false
			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		async deleteTranslation() {
			for (const locale of this.locales) {
				delete this.text[locale.code][this.selectedObjKey]
			}

			await this.updateTranslations()
			
			this.existingKeys.splice(this.existingKeys.findIndex(x => x === this.selectedObjKey), 1)
			this.selectedObjKey = ""
		},
		validateTranslation() {
			this.runValidation = true

			if (this.textLabelError.length) {
				return false		
			}

			this.addTranslation()

			return true
		}
	}
}
</script>

<style scoped>
.flag { position: absolute; z-index: 10; margin-top: 34px; margin-left: -26px; zoom: 0.8; }
</style>