
export class Client {
    constructor() {
      return {
        sys: {id:''},
        fields: {
          title: {de:''},
          clientId: {de:''},
          logoUrl: {de: ''},
          backgroundImage: {de: {}},
          currency: {de:''},
          
          installationId: {de: ''},
          shopClientId: {de:''},
          shopUrl: {de:''},
          kafkaTopics: {de: []},

          features: {de: []},

          contentfulSpace: {de:''},
          contentfulEnvironment: {de:''},

          contingentServiceUrl: {de:''},
          contingentServiceUser: {de:''},
          contingentServicePassword: {de:''},

          isTicketServiceProxy: {de:false},
          ticketServiceUrl: {de:''},
          ticketServiceUser: {de:''},
          ticketServicePassword: {de:''},
          ticketServiceTenant: {de:''},

          jamesCustomerId: {de:''},
          salesChannels: {de: []},
          resellerCategories: {de: []},
          productCategories: {de: []},
          taxClasses: {de: []},
          ticketTypes: {de: []},
          reductions: {de: []},
          defaultAssignments: {de: []},
          
          serviceProviderChecklist: {de: {}},

          peakApiUser: {de:''},
          peakApiUserPassword: {de:''},
          xmlrpcUser: {de:''},
          xmlrpcPassword: {de:''},
          configStoreId: {de:0},

          marketplaceSettings: {
            de: [
              {
                id: "spSalesChannelSelectionEnabled",
                enabled: false
              }
            ]
          },

      }
    }
  }
}