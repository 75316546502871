<template>
	<v-container class="main" fluid fill-width>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div class="toolbar">
			<v-row align="center">
				
			</v-row>
		</div>

		<v-card class="tableCard" style="margin-top:10px">
			<v-data-table fixed-header hide-default-footer
				v-model="selected"
				:headers="headers"
				:items="peakSyncData"
				:items-per-page="limit"
				:mobile-breakpoint="700"
				item-key="name"
				class="elevation-0"
				style="border-radius: 0" 
				>

        <template v-slot:item="{item}">
          <tr :class="rowStyle(item)" @click="showPeakSyncDataDetail(item)">
            <td>{{item.client}}</td>
            <td align="right">{{item.total}}</td>
            <td align="right">{{item.pending}}</td>
            <td align="right">{{item.done}}</td>
            <td align="right">{{item.error}}</td>
          </tr>
        </template>
			</v-data-table>

			<v-card class="tableFooter">
				<v-card-actions>
					<TablePaginator v-model="offset" :limit="limit" :total="count" @input="getPeakSyncDataForClients()" :results="peakSyncData" />
				</v-card-actions>
			</v-card>
		</v-card>

	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Status from '@/components/common/Status.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'PeakSyncView',
	components: { Loading, Alert, TablePaginator, Status },
	mixins: [ Common ],

	data() {
		return {
			offset: this.$store.state.offset,
			limit: 30,
			
			peakSyncData: [],
			count: 0,
			selected: []
		}
	},

	computed: {
		headers () {
			return [
				{ text: 'Client', value: "client", sortable: false },
				{ text: 'Total', value: "total", align:'right', sortable: false,  width: '10%' },
        { text: 'Pending', value: "pending", align:'right', sortable: false,  width: '10%' },
        { text: 'Done', value: "done", align:'right', sortable: false,  width: '10%' },
        { text: 'Error', value: "error", align:'right', sortable: false,  width: '10%' },
			]
		},
	},

	mounted() {
		this.getPeakSyncDataForClients()
	},

	methods: {
    async getPeakSyncDataForClients() {
			this.loading = true
			try {
				await this.$store.commit('setOffset', this.offset)
				
				const res = await this.$httpGet('/peak-sync-clients')
				this.peakSyncData = res.peakSyncData
				this.count = res.total
			}
			catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
    async showPeakSyncDataDetail(item) {
			try {
				await this.$store.commit('setSelectedClientId', item.client)
				this.$router.push('/peaksync-detail')
			} catch (error) {
				this.showError(error)
			}
		},
		rowStyle(item) {
      return item.error > 0 ? 'error-row' : 'clear-row'
    }
	}
}
</script>

