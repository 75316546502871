<template>
	<div v-if="status" class="status"
		:style="{
			'background-color': bgColor,
			'color': 'white',
		}">
		{{ status.replace('_', ' ') }}
	</div>
</template>

<script>
export default {
	name: 'status',
	props: {
		status: String,
	},
	computed: {
		bgColor() {
			if (this.status === 'pending') return '#ff9e21'
      if (this.status === "reapprove") return '#ffb400'
      if (this.status === 'saved') return '#589ae6'
			if (this.status === 'active_saved') return '#589ae6'
			if (this.status === 'active') return '#66BB6A'
			if (this.status === 'deactivated') return '#f34545'
			if (this.status === 'declined') return '#f34545'
			if (this.status === "approved") return '#64c823'
			if (this.status === "expired") return '#737373'
			if (this.status === 'done') return '#66BB6A'
			if (this.status === 'error') return '#f34545'

			//Live Sync Statuses
			if (this.status === 'scheduled') return '#589ae6'
			if (this.status === "in_progress") return '#ffb400'

			return '#cccccc'
		}
	}
}
</script>

<style scoped>
.status {
	padding: 7px 20px;
	border-radius: 10px;
	font-size: 13pt;
	color: white;
	text-align: center;
	cursor: default;
	line-height: initial;
	max-width: 380px;
}
</style>