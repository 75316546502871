<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>
		
		<!-- toolbar -->
		<div class="pa-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>
					&nbsp;<h2>Service Type Detail View</h2>
				</v-row>
			</div>
		</div>

		<SideBar>
			<div class="sidebar-block">
				<p class="sidebar-title">Actions</p>
				<div class="buttonBlock">
					<v-btn block class="btn blue mt-3" elevation="0" dark @click="upsertServiceType()">Save Changes</v-btn>
					<v-btn v-if="serviceTypeStatus==='active'" block class="btn red mt-3" elevation="0" dark @click="changeStatus('deactivated')">Deactivate Service Type</v-btn>
					<v-btn v-if="serviceTypeStatus!='active'" block class="btn green mt-3" elevation="0" dark @click="changeStatus('active')">Activate Service Type</v-btn>
				</div>
			</div>

			<div class="sidebar-block">
				<p class="sidebar-title">Status</p>
				<Status :status="serviceTypeStatus" class="mt-3" />
			</div>

			<div class="sidebar-block">
				<div class="buttonBlock">
					<p class="sidebar-title">Languages</p>
					<v-select outlined dense required hide-details class="mt-3"
						v-model="selectedLocale"
						:items="locales"
						:item-text="locale => locale.name"
						:item-value="locale => locale.code"
					/>
				</div>
			</div>
			
			<div class="sidebar-block">
				<div v-if="serviceType.fields.icon.de.fields" class="preview-container">
					<div class="preview-component">
						<ServiceTypeCard 
							:serviceType="serviceType"
							:locale="selectedLocale" 
							:isPreview="true"
						/>
					</div>
				</div>
			</div>

			<div class="sidebar-block" v-if="action!==Action.CREATE">
				<p class="sidebar-title">Info</p>
				<v-label>{{serviceType.sys.id}}</v-label>
			</div>
		</SideBar>

		<!-- General Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="General Information" :expanded="true" :error="sectionMessage.generalInfo.error" :message="sectionMessage.generalInfo.message">
				<div class="field left-border">
					<v-label>Service Type Name <span class="mandatory">(required)</span></v-label>
					<LanguageFlag v-model="selectedLocale" class="flag" style="margin-top:10px !important"/>
					<v-text-field outlined required
						:hide-details="!serviceTypeNameError.length"
						v-model="serviceType.fields.title[selectedLocale]"
						:error-messages="serviceTypeNameError"
					/>
				</div>

				<div class="field left-border">
					<v-label>Short Description <span class="mandatory">(required)</span></v-label>
					<LanguageFlag v-model="selectedLocale" class="flag" style="margin-top:10px !important"/>
					<v-text-field outlined required
						:hide-details="!shortDescriptionError.length"
						v-model="serviceType.fields.shortDescription[selectedLocale]"
						:error-messages="shortDescriptionError"
					/>
				</div>

				<div class="field left-border">
					<v-label>Ticket Type <span class="mandatory">(required)</span></v-label>
					<v-select outlined dense hide-details class="select ma-rgt" 
						style="max-width:15% !important"
						v-model="serviceType.fields.ticketType.de"
						:items="ticketTypes" 
					/>
				</div>

				<div class="field left-border">
					<v-label>Peak Sync Enabled</v-label>
					<v-switch
						v-model="serviceType.fields.peakSyncEnabled"
						label="Send products to MyServices Connector for import into PEAK" 
					/>
				</div>
				
			</Disclosure>
		</div>

		<!-- Display Settings -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Display Settings" :expanded="false" :error="sectionMessage.displaySettings.error" :message="sectionMessage.displaySettings.message">
				<v-row>
					<v-col>
						<div class="field left-border">
							<v-label>Colour <span class="mandatory">(required)</span></v-label>
							<v-color-picker
								class="ma-2"
								canvas-height="300"
								show-swatches
								swatches-max-height="300px" 
								v-model="serviceType.fields.color.de" 
								:mode.sync="colourMode"
								:hide-details="!colourError.length"
								:error-messages="colourError"
							/>
						</div>
					</v-col>

					<v-col>
						<!-- ServiceTypeCard Icon -->
						<div class="field left-border">
							<v-row style="padding:w10px">
								<v-label>Icon <span class="mandatory">(required)</span></v-label>
								<v-spacer/>
								<v-btn v-if="isUpdateIcon" class="btn" elevation="0" dense @click="cancelIconUpload()">Cancel Upload</v-btn>
							</v-row>
		
							<MediaCard 
								v-if="!isUpdateIcon && serviceType.fields.icon.de.fields"
								:media="serviceType.fields.icon.de" 
								:canEdit="true" 
								:canSelect="false"
								:showTitle="false"
								@show-media-update="showUpdateIcon()"
							/>
							<br/>
							<FileUpload 
								v-if="isUpdateIcon || !serviceType.fields.icon.de.fields"
								:options="dropOptions" 
								ref="fileUploadIcon" 
								@thumbnail-created="onIconThumbnailCreated" 
								@file-removed="cancelIconUpload"
							/>
						</div>
						<br/>
						<!-- List Icon -->
						<div class="field left-border">
							<v-row style="padding:w10px">
								<v-label>Services List Icon</v-label>
								<v-spacer/>
								<v-btn v-if="isUpdateListIcon" class="btn" elevation="0" dense @click="cancelListIconUpload()">Cancel Upload</v-btn>
							</v-row>
		
							<MediaCard 
								v-if="!isUpdateListIcon && serviceType.fields.listIcon"
								:media="serviceType.fields.listIcon.de" 
								:canEdit="true" 
								:canSelect="false"
								:showTitle="false"
								@show-media-update="showUpdateListIcon()"
							/>
							<br/>
							<FileUpload 
								v-if="isUpdateListIcon || !serviceType.fields.listIcon"
								:options="dropOptions" 
								ref="fileUploadListIcon" 
								@thumbnail-created="onListIconThumbnailCreated" 
								@file-removed="cancelListIconUpload"
							/>
						</div>
					</v-col>
				</v-row>
			</Disclosure>
		</div>

		<!-- Service Designer -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Service Designer Sections &amp; Fields" :expanded="false" :error="sectionMessage.templateSettings.error" :message="sectionMessage.templateSettings.message">
				<div class="field left-border">
					<v-label>Service Designer Config <span class="mandatory">(required)</span></v-label>
					<JsonEditor v-model="serviceType.fields.template.de" :plus="true" :options="editorOptions" />
				</div>
			</Disclosure>
		</div>

		<!-- Supported Fields -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Supported Fields" :expanded="false" :error="sectionMessage.templateSettings.error" :message="sectionMessage.templateSettings.message">
				<div class="field left-border">
					<v-row class="pa-3">Select which fields need to be saved in the database for services with this service type. <span class="mandatory">(required)</span></v-row>
					<v-row class="pa-3" align="center"><img src="@/assets/icons/icon-warning.svg"/>&nbsp;<b>Note: Fields that are not selected will not be saved in the database</b></v-row>
					<v-col v-for="key of Object.keys(supportedFields)" :key="key">
						<v-card tile flat style="background-color:transparent">
							<v-checkbox hide-details
								v-model="supportedFields[key].checked"
								:label="key"
							/>
						</v-card>
					</v-col>
				</div>
			</Disclosure>
		</div>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import SideBar from "@/components/common/SideBar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'
import ServiceTypeCard from '@/views/applications/serviceTypes/ServiceTypeCard.vue'
import MediaCard from '@/components/common/MediaCard.vue'
import FileUpload from '@/components/common/FileUpload'
import JsonEditor from '@/components/common/JsonEditor.vue'
import Status from '@/components/common/Status.vue'
import Dialog from '@/components/common/Dialog.vue'
import Common from '@/mixins/Common.vue'
import { Action, EntryStatus } from '@/plugins/enum.js'

export default {
	name: "ServiceTypeDetailView",
	components: { Loading, SideBar, Alert, Disclosure, LanguageFlag, Status, ServiceTypeCard, MediaCard, FileUpload, JsonEditor, Dialog },
	mixins: [ Common ],

	props: {
		initialAction: { type: String }
	},

	data() {
		return {
			Action: Action,
			EntryStatus: EntryStatus,

			action: this.initialAction,

			originalIcon: {},
			originalListIcon: {},
			newIcon: {},
			newListIcon: {},
			colourMode: 'hexa',
			isUpdateIcon: false,
			isUpdateListIcon: false,
			
			dropOptions: {
				acceptedFiles: '.svg',
				addRemoveLinks: true,
				maxFilesize: 10,
				maxFiles: 1
			},
			editorOptions: {
				mode: "code",
				modes: ["tree", "code"],
				onEditable: this.onEditable,
			},
			sectionMessage: {
				generalInfo: {error:false,warning:false,message:''},
				displaySettings: {error:false,warning:false,message:''},
				templateSettings: {error:false,warning:false,message:''}
			},
			ticketTypes: ['event','addon'],
			serviceType: {
				sys: {id:''},
				fields: {
					title: {},
					shortDescription: {},
					icon: {de: {}},
					listIcon: {de: {}},
					color: {de: '#000000'},
					status: 'pending',
					default: false,
					displayIndex: 0,
					peakSyncEnabled: false,
					ticketType: {de: 'event'},
					template: {de: {}},
					supportedFields: {de:[]}
				}
			},
			supportedFields: {
				serviceType: {checked:false},
				title: {checked:false},
				longDescription: {checked:false},
				shortDescription: {checked:false},
				importantInformation: {checked:false},
				images: {checked:false},
				ticket: {checked:false},
				location: {checked:false},
				taxClass: {checked:false},
				vouchers: {checked:false},
				basePrice: {checked:false},
				fromPrice: {checked:false},
				topProduct: {checked:false},
				freeProduct: {checked:false},
				meetingPoint: {checked:false},
				businessHours: {checked:false},
				leadTimeMonths: {checked:false},
				leadTimeWeeks: {checked:false},
				leadTimeDays: {checked:false},
				leadTimeHours: {checked:false},
				clientAssignments: {checked:false},
				serviceProvider: {checked:false},
				contactInfoGroups: {checked:false},
				copyBusinessHours: {checked:false},
				copyContactInfos: {checked:false},
				productCategories: {checked:false},
				notificationEmails: {checked:false},
				personalisationType: {checked:false},
				startAdvertisingDate: {checked:false},
				productAvailabilities: {checked:false},
				averageDurationOfStayInMinutes: {checked:false},
				faqGroup: {checked:false},
				leadTime: {checked:false},
				productContingents: {checked:false}
			},
			template: {
				de: {
					publicInfo: {
							title: {
									label: "Service Name"
							},
							importantInfo: {
									label: "Important Information"
							},
							longDescription: {
									label: "Long Description"
							},
							shortDescription: {
									label: "Short Description"
							}
					},
					ticketInfo: {
							tickets: {
									label: "Tickets"
							},
							taxClass: {
									label: "Tax Class"
							}
					},
					contactInfo: {
							location: {
									label: "Location"
							},
							meetingPoint: {
									label: "Meeting Point Info"
							},
							contactInfoEmail: {
									label: "Contact E-mail Address"
							},
							contactInfoPhone: {
									label: "Contact Phone Number"
							},
							postPurchaseInfo: {
									label: "Post Purchase Info"
							},
							contactInfoAddress: {
									label: "Service Address"
							},
							notificationEmails: {
									label:"Notification E-mails"
							}
					},
					generalInfo: {
							images: {
									label: "Images"
							},
							faqGroup: {
									label: "FAQ Group"
							},
							topProduct: {
									label: "Top Product"
							},
							freeProduct: {
									label: "Free Product"
							},
							averageDurationOfStay: {
									label: "Average Duration of Visit"
							}
					},
					businessInfo: {
							businessHours: {
									label: "Business Hours"
							}
					},
					categoryInfo: {
							productCategories: {
									label: "Product Categories"
							}
					},
					availabilityInfo: {
							leadTime: {
									label: "Lead Time"
							},
							productContingents: {
									label: "Product Contingents"
							},
							startAdvertisingDate: {
									label: "Start Advertising Date"
							},
							productAvailabilities: {
									label: "Product Availabilities"
							}
					},
					salesChannelsInfo: {
						clientAssignments: {
								label: "Client Assignments"
						}
					},
					ticketSettingsInfo: {
						personalisations: {
								label: "Personalisations"
						},
						personalisationType: {
								label: "Personalistion Type"
						}
					}
				}
			},
		}
	},
	
	computed: {
		serviceTypeNameError() {
			if (this.runValidation && !this.checkRequiredForAllLocales(this.locales, this.serviceType.fields.title)) {
				return 'Service Type Name is required for all languages'
			}
			return ''
		},
		shortDescriptionError() {
			if (this.runValidation && !this.checkRequiredForAllLocales(this.locales, this.serviceType.fields.shortDescription)) {
				return 'Short Description is required for all languages'
			}
			return ''
		},
		colourError() {
			if (this.runValidation && !this.serviceType.fields.color.de.length) {
				return 'Colour is required'
			}
			return ''
		},
		iconError() {
			if (this.runValidation && 
				(!this.serviceType.fields.icon.de.fields && !this.newIcon.fields)) {
				return 'Icon is required'
			}
			return ''
		},
		serviceTypeStatus() {
			return this.serviceType.fields.status
		}
	},

	async mounted() {
		if (this.$store.state.selectedServiceType) {
			this.serviceType = this.$store.state.selectedServiceType
			this.originalIcon = JSON.parse(JSON.stringify(this.serviceType.fields.icon.de))
			this.originalListIcon = JSON.parse(JSON.stringify(this.serviceType.fields.listIcon.de))
			this.setSupportedFields()
		}

		if (!this.serviceType.fields.template?.de || Object.keys(this.serviceType.fields.template.de).length === 0) {
			this.serviceType.fields.template = this.template
		}
	},

	methods: {
		switchLocale(locale) {
			this.selectedLocale = JSON.parse(JSON.stringify(locale)).code
			this.$forceUpdate()
		},
		goback() {
			this.$router.push('/servicetypes')
		},
		showUpdateIcon() {
			this.isUpdateIcon = true
		},
		onIconThumbnailCreated(file) {
			this.newIcon = {
				sys: {
					id: file.upload.uuid
				},
				fields:{
					title: {},
					altText: {},
					description: {},
					file: file
				}
			}

			for (const locale of this.locales) {
				this.newIcon.fields.title[locale.code] = `App Icon - ${this.serviceType.fields.title[locale.code]}`
				this.newIcon.fields.altText[locale.code] = `App Icon - ${this.serviceType.fields.title[locale.code]}`
				this.newIcon.fields.description[locale.code] = `App Icon - ${this.serviceType.fields.title[locale.code]}`
			}

			//Preview New Icon
			if (this.serviceType.fields.icon.de.fields) {
				this.serviceType.fields.icon.de.fields.file.de.url = this.$refs.fileUploadIcon.$refs.fileUploadElement.dropzone.files[0].dataURL
			}
		},
		onListIconThumbnailCreated(file) {
			this.newListIcon = {
				sys: {
					id: file.upload.uuid
				},
				fields:{
					title: {},
					altText: {},
					description: {},
					file: file
				}
			}

			for (const locale of this.locales) {
				this.newListIcon.fields.title[locale.code] = `App Icon - ${this.serviceType.fields.title[locale.code]}`
				this.newListIcon.fields.altText[locale.code] = `App Icon - ${this.serviceType.fields.title[locale.code]}`
				this.newListIcon.fields.description[locale.code] = `App Icon - ${this.serviceType.fields.title[locale.code]}`
			}

			//Preview New Icon
			if (this.serviceType.fields.listIcon.de.fields) {
				this.serviceType.fields.listIcon.de.fields.file.de.url = this.$refs.fileUploadListIcon.$refs.fileUploadElement.dropzone.files[0].dataURL
			}
		},
		cancelIconUpload() {
			this.newIcon = {},
			this.isUpdateIcon = false
			this.serviceType.fields.icon.de = JSON.parse(JSON.stringify(this.originalIcon))
		},
		cancelListIconUpload() {
			this.newListIcon = {},
			this.isUpdateListIcon = false
			this.serviceType.fields.listIcon.de = JSON.parse(JSON.stringify(this.originalListIcon))
		},
		async setSupportedFields() {
			if (this.serviceType.fields.supportedFields?.length) {
				for (const field of this.serviceType.fields.supportedFields) {
					this.supportedFields[field].checked = true
				}
			}
		},
		validateServiceTypeDetails() {
			this.runValidation = true
			let isValid = true

			if (isValid === false) {
				this.errorTitle = 'ERROR'
				this.errorDetail = 'Please enter the required information'
			}
			
			return isValid
		},
		async upsertServiceType() {
			const isValid = this.validateServiceTypeDetails()
			if (!isValid) return

			this.loading = true
			
			try {
				if (Object.keys(this.newIcon).length > 0) {
					this.serviceType.fields.icon.de = this.newIcon
				}

				if (Object.keys(this.newListIcon).length > 0) {
					this.serviceType.fields.listIcon.de = this.newListIcon
				}
				
				//Set Supported Fields
				this.serviceType.fields.supportedFields = []
				for (const key of Object.keys(this.supportedFields)) {
					if (this.supportedFields[key].checked === true) {
						this.serviceType.fields.supportedFields.push(key.toString())
					}
				}

				const data = {
					serviceType: this.serviceType
				}

				this.serviceType = await this.$httpPost(`/service-type`, data)
				
				this.successTitle = 'UPDATE SERVICE TYPE'
				this.successDetail = 'Service Type updated successfully'

			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		async changeStatus(status) {
			this.serviceType.fields.status = status
			await this.upsertServiceType()
		}
	}
}
</script>

<style scoped>
.flag { position: absolute; z-index: 10; top: 54px; margin-left: -26px; zoom: 0.8; }
.preview-container {
	width: 95%;
	height: 400px;
	padding-top: 75%;
	position: relative;
	margin: auto;
}
.preview-component {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	border: 1px solid grey;
	border-radius: 10px;
}
li {
	font-size: 16px;
}
</style>