<template>
	<v-container class="main" fluid fill-width>
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div class="toolbar">
			<v-row align="center">
				<v-spacer/>
			</v-row>
		</div>

		<v-card class="tableCard" style="margin-top:10px">
			<v-data-table fixed-header hide-default-footer
				v-model="selected"
				:headers="headers"
				:items="clients"
				:items-per-page="limit"
				:mobile-breakpoint="700"
				item-key="name"
				class="elevation-0"
				style="border-radius: 0" 
				>

        <template v-slot:item="{item}">
          <tr @click="viewLiveSyncRunsForClient(item)">
            <td>{{item.title}}</td>
						<td align="left">
							<span v-if="item.lastSyncDate">{{item.lastSyncDate | formatShortDateTime }}</span>
							<span v-if="!item.lastSyncDate">-</span>
						</td>
						<td align="center">
							<Status :status="item.status" />
						</td>
          </tr>
        </template>
			</v-data-table>

			<v-card class="tableFooter">
				<v-card-actions>
					<TablePaginator v-model="offset" :limit="limit" :total="count" @input="getLiveClients()" :results="clients" />
				</v-card-actions>
			</v-card>
		</v-card>

	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Alert from '@/components/common/Alert.vue'
import TablePaginator from '@/components/common/TablePaginator.vue'
import Status from '@/components/common/Status.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: 'LiveSyncView',
	components: { Loading, Alert, TablePaginator, Status },
	mixins: [ Common ],

	data() {
		return {
			offset: this.$store.state.offset,
			limit: 15,

			count: 0,
			selected: [],

			clients: [],
			selectedClientId: ''
		}
	},

	computed: {
		headers () {
			return [
				{ text: 'Client', value: "client", sortable: false },
				{ text: 'Last Sync Date', value: "lastSyncDate", sortable: false, width: '150px' },
				{ text: 'Status', value: "status", sortable: false, align: 'center', width: '15%' },
			]
		}
	},

	mounted() {
		this.getLiveClients()
	},

	methods: {
		async getLiveClients() {
			this.loading = true
			try {
				await this.$store.commit('setOffset', this.offset)
				let url = `/live-sync/clients?skip=${this.offset}&limit=${this.limit}`
				
				const res = await this.$httpGet(url)

				this.clients = res.clients
				this.count = res.total
			
			} catch (error) {
				this.showError(error)
			}
			this.loading = false
		},
		
		async viewLiveSyncRunsForClient(item) {
			await this.$store.commit('setSelectedClient', item.id)
			await this.$store.commit('setSelectedLiveSync', null)
			this.$router.push('/livesync-detail')
		}

	}
}
</script>

