<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>

		<!-- toolbar -->
		<div class="pa-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>
					&nbsp;<h2>{{ $store.state.selectedProject }} Release {{ release.version }}</h2>
				</v-row>
			</div>
		</div>
		
		<SideBar>
			<div class="buttonBlock">
				<v-btn block class="btn blue mt-3" elevation="0" dark @click="upsertRelease()"><v-icon>mdi-check</v-icon>Save Changes</v-btn>
				<v-btn block class="btn mt-3" elevation="0" :disabled="release.id===''" @click="getJiraIssues()"><v-icon>mdi-refresh</v-icon>Refresh Issue List</v-btn>
				<v-btn block class="btn mt-3" elevation="0" :disabled="!selectedCampaign" @click="sendEmmaCampaign()"><v-icon>mdi-mail</v-icon>Send EMMA Campaign</v-btn>
			</div>
			<br/>
			<v-card class="info mb-3 pa-3" flat dark v-if="release.id===''">The release needs to be saved before the Jira issues can be retrieved</v-card>
			<br/>
			<div class="buttonBlock">
				<p class="sidebar-title">Languages</p>
				<v-select outlined dense required hide-details class="mt-3"
					v-model="selectedLocale"
					:items="locales"
					:item-text="locale => locale.name"
					:item-value="locale => locale.code"
				/>
			</div>
			<br/>
		</SideBar>

		<!-- General Information -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Release Overview" :expanded="true">
				<div class="field left-border">
					<v-label>Version <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required 
						:hide-details="!versionError.length"
						v-model="release.version"
						:error-messages="versionError"
					/>
				</div>

				<div class="field left-border">
					<v-label>Jira Release Label <span class="mandatory">(required)</span></v-label>
					<v-text-field outlined required 
						:hide-details="!labelError.length"
						v-model="release.label"
						:error-messages="labelError"
					/>
				</div>
			</Disclosure>
		</div>

		<!-- EMMA Config -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="EMMA Config" :expanded="true">
				<div class="field left-border">
					<v-label>E-mail Body</v-label>
					<LanguageFlag v-model="selectedLocale" class="flag" style="margin-top:10px !important"/>
					<v-textarea outlined required hide-details
						v-model="release.emmaInfo.emailBody[selectedLocale]"
					/>
				</div>

				<div class="field left-border">
					<v-label>'More Information' Link</v-label>
					<v-text-field outlined required hide-details
						v-model="release.emmaInfo.moreInfoLink"
					/>
				</div>

				<div class="field left-border">
					<v-label>Customer Feedback Confluence Page Link</v-label>
					<v-text-field outlined required hide-details
						v-model="release.emmaInfo.testingFeedbackLink"
					/>
				</div>

				<div class="field left-border">
					<v-label>Campaign</v-label>
					<v-select outlined required hide-details class="mt-3"
						v-model="selectedCampaign"
						return-object
						:items="emmaCampaigns"
						:item-text="campaign => campaign.name"
						:item-value="campaign => campaign.id"
					/>
				</div>
			</Disclosure>
		</div>

		<!-- Linked Jira Issues -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="EMMA Jira Issues" :expanded="true">
				<div v-if="release.id!=''" class="field left-border">
					<v-label>Features</v-label>
					<div v-if="release.issues" class="mb-3">
						<div v-for="issue of release.issues" :key="issue.id">
							<v-row v-if="issue.type==='Task'" align="center">
								<v-checkbox hide-details v-model="issue.includeEmma"/>
								<div style="padding-top:17px"><span class="issueKey">{{ issue.key }}</span> {{ issue.summary }}</div>
							</v-row>
						</div>
					</div>
				</div>

				<div v-if="release.id!=''" class="field left-border">
					<v-label>Improvements</v-label>
					<div v-if="release.issues" class="mb-3">
						<div v-for="issue of release.issues" :key="issue.id">
							<v-row v-if="issue.type!='Task'" align="center">
								<v-checkbox hide-details v-model="issue.includeEmma"/>
								<div style="padding-top:17px"><span class="issueKey">{{ issue.key }}</span> {{ issue.summary }}</div>
							</v-row>
						</div>
					</div>
				</div>
			</Disclosure>
		</div>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import Common from '@/mixins/Common.vue'
import SideBar from "@/components/common/SideBar"
import Alert from '@/components/common/Alert.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import LanguageFlag from '@/components/common/LanguageFlag.vue'

export default {
	name: "ReleasenDetailView",
	mixins: [ Common ],
	components: { Loading, SideBar, Alert, Disclosure, LanguageFlag },
	props: {
		locale: String,
	},
	data() {
		return {
			emmaCampaigns: [],
			selectedCampaign: null,
			
			release: {
				id: this.$store.state.selectedRelease?.id ? this.$store.state.selectedRelease.id : '',
				projectKey: this.$store.selectedProject ? this.$store.selectedProject : 'MYS',
				version: '',
				label: '',
				status: '',
				emmaInfo: {
					emailBody: {de:'',en:''},
					moreInfoLink: '',
					testingFeedbackLink: '',
					testingNotification: {
						sent: false,
						sentDate: ''
					},
					releaseNotification: {
						sent: false,
						sentDate: ''
					}
				},
				issues: []
			},

			emailRegex: '/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,10}$/'
		}
	},
	async mounted() {
		this.getEmmaCampaigns()

		if (this.$store.state.selectedRelease) {
			this.release = this.$store.state.selectedRelease
			this.release.issues.sort((a, b) => (a.key > b.key) ? 1 : -1)
		}
	},
	computed: {
		versionError() {
			if (this.runValidation && !this.release.version.length) {
				return 'Version is required'
			}
			return ''
		},
		labelError() {
			if (this.runValidation && !this.release.label.length) {
				return 'Jira Release Label is required'
			}
			return ''
		}
	},
	methods: {
		goback() {
			this.$router.push('/releases')
		},
		emailError(email) {
			if (email?.length > 0) {
				if (!this.emailRegex.test(String(email).toLowerCase())) {
					return 'Invalid e-mail address'
				}
			}
		},
		async getEmmaCampaigns() {
			try {
				this.emmaCampaigns = await this.$httpGet(`/campaigns/MYS`)
			}
			catch (error) {
				if (error.response?.status == 401) return this.$emit("show-login")
				this.showError(error)
			}
		},
		async getReleaseDetail() {
			try {
				this.loading = true
				this.release = await this.$httpGet(`/release/${this.release.id}`)
				this.release.issues.sort((a, b) => (a.key > b.key) ? 1 : -1)
				this.loading = false
			} catch (error) {
				this.showError(error)
			}
		},
		async getJiraIssues() {
			try {
				this.loading = true

				if (this.release.id != '') {
					this.release.issues = await this.$httpGet(`/jiraIssues/${this.release.id}`)
					
					this.getReleaseDetail()
				}
				
				this.loading = false
			} catch (error) {
				this.showError(error)
			}
		},
		validateReleaseDetails() {
			this.runValidation = true
			let isValid = true

			if (isValid === false) {
				this.errorTitle = 'ERROR'
				this.errorDetail = 'Please enter the required information'
			}
			
			return isValid
		},
		async upsertRelease() {
			const isValid = this.validateReleaseDetails()
			if (!isValid) return

			this.loading = true
			
			try {
				const data = {
					release: this.release
				}

				this.release = await this.$httpPost(`/release`, data)
				this.release.issues.sort((a, b) => (a.key > b.key) ? 1 : -1)
				
				this.successTitle = 'UPDATE RELEASE'
				this.successDetail = 'Release updated successfully'

			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		},
		async sendEmmaCampaign() {
			await this.upsertRelease()

			this.loading = true
			
			try {
				const data = {
					campaignId: this.selectedCampaign.campaignId,
					version: this.release.version,
					recipients: this.campaignRecipients
				}

				await this.$httpPost(`/send-emma-campaign`, data)
				
				this.successTitle = 'SEND EMMA Campaign'
				this.successDetail = 'EMMA Campaign sent successfully'

			} catch (error) {
				this.showError(error)
			}
			
			this.loading = false
		}
	}
}
</script>

<style scoped>
.flag { position: absolute; z-index: 10; top: 54px; margin-left: -26px; zoom: 0.8; }
.issueKey { color: #4DB6AC }
</style>