<template class="justify-center">
    <div fluid fill-width>
      <div class="field left-border">
        <v-row class="pa-3">
          <span style="width:200px">Client</span><v-label>{{ peakSyncData.client }}</v-label>
        </v-row>
        <v-row class="pa-3">
          <span style="width:200px">PEAK ID</span>
          <v-label v-if="parseInt(peakSyncData.entityId)">{{ peakSyncData.entityid }}</v-label>
          <v-label v-if="!parseInt(peakSyncData.entityId)">-</v-label>
        </v-row>
        <v-row class="pa-3" align="center">
          <span style="width:200px">Status</span><v-label><Status :status="peakSyncData.status" /></v-label>
        </v-row>
      </div>

      <div class="field left-border">
          PEAK Product Data
					<JsonEditor v-model="peakSyncData.data" :plus="true" :options="editorOptions" />
				</div>
    </div>
</template>

<script>
import JsonEditor from '@/components/common/JsonEditor.vue'
import Status from '@/components/common/Status.vue'
import Common from '@/mixins/Common.vue'

export default {
	name: "PeakSyncData",
	mixins: [ Common ],
  components: { Status, JsonEditor },
	props: {
		peakSyncData: Object
	},

  data() {
		return {
      editorOptions: {
				mode: "code",
				modes: ["tree", "code"],
				onEditable: this.onEditable,
			},
    }
  }
  
}
</script>
