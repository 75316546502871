<script>
export default {
	data() {
		return {
			loading: false,
			errorTitle: '',
			errorDetail: '',
			successTitle: '',
			successDetail: '',
			runValidation: false,

			locales: this.$store.state.locales,
			selectedLocale: this.$store.state.selectedLocale,
			defaultLocale: this.$store.state.defaultLocale,

			schema: this.$store.state.schema
		}
	},

  methods: {
		async clearLocalStorage() {
			await this.$store.commit('setLoggedInUser', null)
		},

    showError(error) {
			this.loading = false
			const detail = error?.response?.data?.error ? error.response.data.error : error.error ? error.error : error
			
			this.errorTitle = 'ERROR'
			this.errorDetail = detail
		},

		isWaiting() {
			return this.loading || !!this.successTitle || !!this.errorTitle
		},

		sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		},
		
		getAssetUrl(asset, locale = 'de') {
			if (asset?.fields?.file?.[locale]?.url?.length) {
				let url = asset.fields?.file?.[locale]?.url
				if (!url && locale != 'de') return asset.fields.file.de?.url
				if (url.substr(0, 2) == '//') return 'https:' + url
				return url
			}
		},

		checkRequiredForAllLocales(locales, fieldValue) {
			let hasTextForAllLocales = true
			for (const locale of locales) {
				if (!fieldValue[locale.code]?.length) {
					hasTextForAllLocales = false
					break
				}
			}
			return hasTextForAllLocales
		},

		validEmail(email) {
			return (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email))
		},

		requestHeaders() {
			return {
				Authorization: 'Bearer ' + this.$store.state.loggedInUser.kc_token,
				Accept: 'application/json, text/plain, */*',
				'Content-Type': 'application/json',
				'mys-user-id': this.$store.state.loggedInUser?.sys?.id ? this.$store.state.loggedInUser.sys.id : '',
				'mys-user-type': this.$store.state.loggedInUser?.fields?.type?.de ? this.$store.state.loggedInUser.fields.type.de : ''
			}
		},

		getPattern(patternSource) {
      const parts = patternSource.split('/')
      let images

      switch (parts[0]) {
        case 'red': images = require.context(`@/assets/patterns/red/`, false, /\.png$/); break
        case 'orange': images = require.context(`@/assets/patterns/orange/`, false, /\.png$/); break
        case 'yellow': images = require.context(`@/assets/patterns/yellow/`, false, /\.png$/); break
        case 'green': images = require.context(`@/assets/patterns/green/`, false, /\.png$/); break
        case 'blue': images = require.context(`@/assets/patterns/blue/`, false, /\.png$/); break
        case 'purple': images = require.context(`@/assets/patterns/purple/`, false, /\.png$/); break
      }
      return images('./' + parts[1])
    },

		async getLocales() {
			try {
				if (!this.$store.state.locales) {
					const locales = await this.$httpGet(`/locales`)
					await this.$store.commit('setLocales', locales)
					await this.$store.commit('setDefaultLocale', locales[0])
					await this.$store.commit('setSelectedLocale', locales[0].code)
				}
			}
			catch (error) {
				if (error.response?.status == 401) return this.$emit("show-login")
				this.showError(error)
			}
		},

		async getSchema() {
			if (!this.$store.state.schema) {
				const schema = await this.$httpGet(`/schema`)
				await this.$store.commit('setSchema', schema)
			}
		},

		userMayEditFields() {
			return this.$store.state.loggedInUser.fields.type.de === 'engineer'
		}
  }
}
</script>