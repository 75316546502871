<template>
	<v-container fluid class="pa-2 mb-12 detail-container">
		<Alert v-model="errorTitle">{{ errorDetail }}</Alert>
		<Alert v-model="successTitle" type="success">{{ successDetail }}</Alert>
		<loading :active.sync="loading" :is-full-page="true" color="#4caf50"></loading>
		
		<!-- toolbar -->
		<div class="pa-3" style="width: 100%">
			<div class="toolbar">
				<v-row align="center">
					<v-btn class="btn" small elevation="0" @click="goback()">
						<v-icon>mdi-arrow-left-circle</v-icon>
					</v-btn>&nbsp;
					<span v-if="action==='view'">
						<h2>{{ user.fields.email.de }}</h2>
					</span>
					<span v-if="action==='create'">
						<h2>New {{userType}} User</h2>
					</span>
				</v-row>
			</div>
		</div>

		<SideBar>
			<div class="sidebar-block">
				<div class="buttonBlock">
					<p class="sidebar-title">Actions</p>
					<v-btn block class="btn blue mt-3" elevation="0" dark @click="upsertUser()">Save Changes</v-btn>
					<v-btn v-if="action!==Action.CREATE" block class="btn grey mt-3" elevation="0" @click="$refs.resetPasswordDialog.show=true"><v-icon>mdi-key</v-icon>Reset Password</v-btn>
					<v-btn v-if="action!==Action.CREATE && !userIsServiceProvider" block class="btn red mt-3" elevation="0" dark @click="$refs.deleteDialog.show=true"><v-icon>mdi-delete</v-icon>Delete Account</v-btn>
				</div>
			</div>

			<div class="sidebar-block">
				<p class="sidebar-title">User Type</p>
				<ChipLabel :value="user.fields.type.de" class="mt-3" />
			</div>

			<div class="sidebar-block" v-if="action!==Action.CREATE">
				<p class="sidebar-title">Info</p>
				<v-label>{{user.sys.id}}</v-label>
			</div>

    </SideBar>

		<!-- Login Details -->
		<div class="pa-3" style="width: 100%">
				<Disclosure title="Login Details" :error="sectionMessage.userAccount.error" :message="sectionMessage.userAccount.message">
					<div class="field left-border" v-if="action!==Action.CREATE">
						<v-label>User ID</v-label>
						<v-text-field outlined dense readonly
							v-model="user.sys.id"
							hint="field is read only and cannot be changed" persistent-hint
						/>
					</div>
					<div class="field left-border" v-if="action!==Action.CREATE">
						<v-label>Keycloak ID</v-label>
						<v-text-field outlined dense readonly
							v-model="user.fields.keycloakId.de"
							hint="field is read only and cannot be changed" persistent-hint
						/>
					</div>
					<div class="field left-border">
						<v-label>Account Name</v-label>
						<v-text-field outlined dense hide-details autocomplete="off" required
							id="userAccountName"
							v-model="user.fields.name.de"
						/>
					</div>
					<div class="field left-border">
						<v-label>Login E-mail <span class="mandatory">(required)</span></v-label>
						<v-text-field outlined dense required autocomplete="off"
							:hide-details="!userAccountEmailErrors.length"
							id="username"
							v-model="user.fields.email.de"
							:error-messages="userAccountEmailErrors"
							@blur="checkEmailExists"
						/>
					</div>
					<div class="field left-border" v-if="action===Action.CREATE && isAlturosUser===false">
						<v-label>Password  <span class="mandatory">(required)</span></v-label>
						<v-text-field id="password" type="password" outlined dense
							v-model="user.fields.password.de"
							autocomplete="off"
							:error-messages="passwordErrors"
							@input="runValidation=true"/>
						<br/>
						<v-label>Repeat Password  <span class="mandatory">(required)</span></v-label>
						<v-text-field id="repeatPassword" type="password" outlined dense
							v-model="repeatPassword"
							autocomplete="off"
							:error-messages="repeatPasswordErrors"
							@input="runValidation=true"/>

					</div>
				</Disclosure>
		</div>

		<!-- Service Provider Details -->
		<div v-if="userIsServiceProvider" class="pa-3" style="width: 100%">
			<Disclosure title="Service Provider Details">
				<ServiceProviderDetail :serviceProvider="user.fields.serviceProvider"/>
			</Disclosure>
		</div>

		<!-- Application Access -->
		<div class="pa-3" style="width: 100%">
			<Disclosure title="Application Access" :error="sectionMessage.applicationAccessInfo.error" :message="sectionMessage.applicationAccessInfo.message">
				<div class="field left-border">
					<div class="flex-container pa-3">
						<div v-for="application of applications" :key="application.sys.id">
							<ApplicationSelector 
								:application="application" 
								:selectedApplications="selectedApplications" 
								v-on:add-application="addSelectedApplication" 
								v-on:remove-application="removeSelectedApplication"
							/>
						</div>
					</div>
				</div>
			</Disclosure>
		</div>

		<!-- Clients -->
		<div v-if="userIsOperator" class="pa-3" style="width: 100%">
			<Disclosure title="Client Access" :error="sectionMessage.clientAccess.error" :message="sectionMessage.clientAccess.message">
				<div class="field left-border">
					<v-row no-gutters>
						<v-col v-for="client of clients" :key="client.sys.id" cols="12" sm="4">
							<v-card class="pa-2" tile flat style="background-color:transparent">
								<v-checkbox
									v-model="client.selected"
									:label="client.fields.title.de"
								/>
							</v-card>
						</v-col>
					</v-row>
				</div>
			</Disclosure>
		</div>

		<!-- Tags -->
		<div v-if="userIsOperator" class="pa-3" style="width: 100%">
			<Disclosure title="Tag Assignment">
				<TagAssignment ref="tagAssignment" :canManageTags="canManageTags" :canSeeTags="canSeeTags" v-on:update-manage-tags="updateCanManageTag" v-on:update-see-tags="updateCanSeeTag"/>
			</Disclosure>
		</div>

		<!-- Change Log -->
		<div v-if="action!==Action.CREATE" class="pa-3" style="width: 100%">
			<AuditLog :entryId="userId" :contentType="'user'"/>
		</div>	

		<!-- Confirm Delete -->
		<Dialog ref="deleteDialog"
			confirmLabel="Delete"
			cancelLabel="Cancel"
			:confirm-handler="deleteUser"
			:cancel-handler="onActionCancelled"
			:showClose="false"
      title="Delete Account"
			:height="'200px'"
			:width="'600px'">
			<template #content>
				<div class="pa-3" style="width:100%">
					<v-container fluid>
						<div>Are you sure you want to delete this user account?</div>
					</v-container>
				</div>
			</template>
		</Dialog>

		<!-- Reset Password -->
		<Dialog ref="resetPasswordDialog"
			confirmLabel="Change Password"
			cancelLabel="Cancel"
			:confirm-handler="changePassword"
			:cancel-handler="onActionCancelled"
			:showClose="false"
      title="Reset Password"
			:height="'400px'"
			:width="'600px'">
			<template #content>
				<div class="pa-3" style="width:100%">
					<ResetPassword ref="resetPassword" :user="user" />
				</div>
			</template>
		</Dialog>
	</v-container>
</template>

<script>
import Loading from 'vue-loading-overlay'
import ApplicationSelector from '@/components/common/ApplicationSelector.vue'
import Disclosure from '@/components/common/Disclosure.vue'
import Alert from '@/components/common/Alert.vue'
import Dialog from '@/components/common/Dialog.vue'
import TagAssignment from './TagAssignment.vue'
import ResetPassword from './ResetPassword.vue'
import ServiceProviderDetail  from './ServiceProviderDetail.vue'
import SideBar from "@/components/common/SideBar.vue"
import ChipLabel from '@/components/common/ChipLabel.vue'
import AuditLog from '@/components/auditLog/AuditLog.vue'
import Common from '@/mixins/Common.vue'
import { Action } from '@/plugins/enum.js'
import _ from 'lodash'

import { User } from "@/models/user.ts"

export default {
	name: 'UserDetail',
	components: { SideBar, ChipLabel, Loading, ApplicationSelector, Disclosure, Alert, Dialog, TagAssignment, ResetPassword, ServiceProviderDetail, AuditLog },
	mixins: [ Common ],

	props: {
		initialAction: { type: String },
		userType: { type: String }
	},

	data() {
		return {
			Action: Action,
			
			activeLink: '',
			submitButtonTheme: 'gradientButton',

			emailIsUnique: true,
			cols: 2,
			userId: '',
			action: this.initialAction,

			sectionMessage: {
				userAccount: {error:false,warning:false,message:''},
				applicationAccessInfo: {error:false,warning:false,message:''},
				clientAccess: {error:false,warning:false,message:''}
			},

			applications:[],
			selectedApplications:[],
			clients: [],
			selectedClients: [],
			canManageTags: [],
			canSeeTags: [],
			password: '',
			repeatPassword: '',

			isAlturosUser: false,

			user: new User()
		}
	},

	computed: {
		userIsOperator() {
			return this.user.fields.type.de === 'operator'
		},
		userIsServiceProvider() {
			return this.user.fields.type.de === 'serviceprovider'
		},
		userAccountEmailErrors() {
			if (!this.runValidation) return []
			let errors = []
			if (this.user.fields.email.de.length === 0) {
				errors.push('E-Mail Address is required')
			} else if (this.user.fields.email.de.length < 5) {
				errors.push('E-Mail Address must be at least 5 characters')
			} else if (this.user.fields.email.de.length > 256) {
				errors.push('E-Mail Address must be less than 256 characters')
			} else if (!this.validEmail(this.user.fields.email.de)) {
			 	errors.push('Must be a valid e-mail')
			} else if (!this.emailIsUnique) {
			 	errors.push('E-mail is already used by another account')
			}
			return errors
		},
		passwordErrors() {
			if (!this.runValidation) return []
			let errors = []
			if (this.user.fields.password?.de?.length === 0) {
				errors.push('Password is required')
			} else if (this.user.fields.password?.de?.length < 8) {
				errors.push('Password must be at least 8 characters')
			}
			return errors
		},
		repeatPasswordErrors() {
			if (!this.runValidation) return []
			let errors = []
			if (this.repeatPassword.length === 0) {
				errors.push('Repeat Password is required')
			} else if (this.repeatPassword !== this.user.fields.password.de) {
				errors.push('Passwords must be identical')
			}
			return errors
		},
		applicationAccessErrors() {
			if (!this.runValidation) return ''
			if (this.selectedApplications.length === 0) {
				return 'Please enter the required information'
			}
			return ''
		}
	},

	async mounted() {
		this.loading = true
		
		await this.getClients()

		if (this.action === Action.CREATE) {
			//Create New User
			this.user.fields.type.de = this.userType
			await this.getApplications('all', this.user.fields.type.de)
		} else {
			await this.getUserDetail()
		}

		this.loading = false
	},

	methods: {
		clearSectionMessage(section) {
			if (section) {
				this.sectionMessage[section] = {error:false,warning:false,message:''}
			}
		},
		onActionCancelled() {
			this.$refs.deleteDialog.show = false
			this.$refs.resetPasswordDialog.show = false
		},
		addSelectedApplication(application) {
			this.selectedApplications.push(application);
		},
		removeSelectedApplication(application) {
			if (this.selectedApplications.length > 0) {
				for (var i=0; i < this.selectedApplications.length; i++) {
					if (this.selectedApplications[i].sys.id === application.sys.id) {
						this.selectedApplications.splice(i, 1);
					}
				}
			}
		},
		updateCanManageTag(tags) {
			//Force 'ALL' tag to uppercase, and remove other tags if 'ALL' is present
			const hasAll = tags?.find(tag => tag.toUpperCase() === 'ALL')
			if (hasAll) { tags = ['ALL'] }

			this.canManageTags = tags
			this.user.fields.canManageTags = { de: tags }
		},
		updateCanSeeTag(tags) {
			//Force 'ALL' tag to uppercase, and remove other tags if 'ALL' is present
			const hasAll = tags?.find(tag => tag.toUpperCase() === 'ALL')
			if (hasAll) { tags = ['ALL'] }
			
			this.canSeeTags = tags
			this.user.fields.canSeeObjectsWithTags = { de: tags }
		},
		async getUserDetail() {
			var id = this.$store.state.selectedUser?.fields?.keycloakId?.de
			if (!id) return

			this.loading = true
			try {
				const user = await this.$httpGet(`/user/${id}`)
				this.user = _.merge(this.user, user)
				
				const clientId = this.user?.fields?.clients?.de?.[0]?.sys?.id ? this.user.fields.clients.de[0].sys.id : 'all'

				await this.getApplications(clientId, this.user.fields.type.de)
				this.selectedApplications = this.user.fields.applications.de

				await this.getClients()
				this.canManageTags = this.user.fields?.canManageTags?.de?.length ? this.user.fields.canManageTags.de : []
				this.canSeeTags = this.user.fields?.canSeeObjectsWithTags?.de?.length ? this.user.fields.canSeeObjectsWithTags.de : []

				this.userId = this.user.sys.id
			}
			catch (error) {
				if (error.response?.status === 401) return this.$emit("show-login")
				console.error(error)
				this.showError(error)
			}
			this.loading = false
		},
		async upsertUser() {
			if (!this.validate()) {
				return this.showError('Please enter the required information')
			}

			try {
				this.loading = true

				//Set Clients
				if (this.user.fields.type.de === 'operator') {
					this.user.fields["clients"] = { de:[] }
					
					if (this.clients.length > 0) {
						for (const client of this.clients) {
							if (client.selected === true) {
								this.user.fields.clients.de.push(client.sys.id)
							}
						}
					}
				}

				//Set Applications
				this.user.fields.applications.de = []
				if (this.selectedApplications.length > 0) {
					for (const application of this.selectedApplications) {
						this.user.fields.applications.de.push(application.sys.id)
					}
				}

				const res = await this.$httpPost('/user', this.user)

				this.user.sys.id = res.userId
				this.user.fields.keycloakId.de = res.keycloakId

				await this.$store.commit('setSelectedUser', this.user)

				if (this.action === Action.CREATE) {
					this.successTitle = 'CREATE USER'
					this.successDetail = 'User created successfully'
				} else {
					this.successTitle = 'UPDATE USER'
					this.successDetail = 'User updated successfully'
				}

				this.userId = ''
				this.action = Action.VIEW
				
				this.sleep(2000).then(() => { this.getUserDetail() })

				this.loading = false
			} catch (error) {
				this.showError(error)
			}
		},
		async deleteUser() {
			this.loading = true
			this.$refs.deleteDialog.show = false

			await this.$httpDelete(`/user/${this.user.sys.id}`)

			this.loading = false

			this.successTitle = 'USER DELETED'
			this.successDetail = 'User deleted sucessfully'

			this.sleep(2000).then(() => { this.goback() })
		},
		changePassword() {
			this.isLoading = true;

			try {
				var data = {
					email: this.user.fields.email.de,
					currentPassword: '',
					password: this.$refs.resetPassword.password,
					adminReset: true
				};

				const res = this.$httpPost('/reset-password', data)
				
				this.$refs.resetPasswordDialog.show = false
				this.successTitle = 'PASSWORD CHANGED'
				this.successDetail = 'The password has been changed'

			} catch(error) {
				this.errorTitle = 'ERROR'
				this.errorDetail = 'There was a problem changing the password'
			}
		},
 		validate() {
			this.runValidation = true
			this.sectionMessage.userAccount = {error:false,warning:false,message:''}
			this.sectionMessage.applicationAccessInfo = {error:false,warning:false,message:''}

			const isValidUserAccount = this.validateUserAccount()
			const isValidApplicationAccess = this.validateApplicationAccess()

			if (isValidUserAccount &&
				isValidApplicationAccess) {
				return true
			}

			return false
		},
		validateUserAccount() {
			let isValid = true

			if (this.userAccountEmailErrors.length) {
				isValid = false
			}

			if (!isValid) {
				this.sectionMessage.userAccount.error = true
				this.sectionMessage.userAccount.message = "Please enter details for the login details"
			}

			return isValid
		},
		validateApplicationAccess() {
			this.sectionMessage.applicationAccessInfo = {error:false,warning:false,message:''}
			let isValid = true

			if (this.selectedApplications.length === 0) {
				isValid = false
				this.sectionMessage.applicationAccessInfo.error = true
				this.sectionMessage.applicationAccessInfo.message = 'Please enter the required information'
			}

			return isValid
		},
		async checkEmailExists() {
			this.isAlturosUser = false

			if (!this.validEmail(this.user.fields.email.de)) {
				this.runValidation = true
			} else {
				this.loading = true
				const res = await this.$httpGet(`/check-email?email=${this.user.fields.email.de}&kcId=${this.user.fields.keycloakId.de}&userType=${this.userType}`)
				this.loading = false

				if (res?.kcUser) {
					if (!res.adminUser) {
						this.emailIsUnique = false
						this.runValidation = true
					} else {
						//Alturos User
						this.user.fields.keycloakId.de = res.adminUser.id
						this.user.fields.email.de = res.adminUser.email
						this.user.fields.name.de = res.adminUser.firstName + ' ' + res.adminUser.lastName

						this.emailIsUnique = true
						this.isAlturosUser = true
					}
					
				} else {
					this.emailIsUnique = true
				}
			}
		},
		async getApplications(client, userType) {
			try {
				
				if (userType) {
					let clientId = userType === 'serviceProvider' ? client.sys.id : 'all'
					this.applications = await this.$httpGet(`/applications/${clientId}/${userType}`)

					this.applications.sort((a, b) => (a.fields.title.en > b.fields.title.en) ? 1 : -1)
				}
			} catch (error) {
				this.showError(error)
			}
		},
		async getClients() {
			try {
				const res = await this.$httpGet(`/clients`)
				this.clients = res.clients

				if (this.clients?.length) {
					this.clients.sort((a, b) => (a.fields.title.de > b.fields.title.de) ? 1 : -1)

					if (this.user?.fields?.clients?.de?.length) {
						for (const selectedClient of this.user.fields.clients.de) {
							const index = this.clients?.findIndex(x => x.sys.id === selectedClient?.sys?.id)
							if (index >= 0) {
								this.clients[index].selected = true
							}
						}
					}
				}
			} catch (error) {
				this.showError(error)
			}
		},
		async goback() {
			await this.$store.commit('setSelectedUser', null)
			this.$router.push("/usermanager")
		}
	}
}
</script>

<style scoped lang="scss">
.error { color: #ff0022; }
.col-container { display: flex }
.col { margin: 10px; flex-grow: 1; display: flex; flex-direction: column; }
.flex-container {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	gap: 10px 20px;
	row-gap: 10px;
	column-gap: 20px;
}

</style>
